import { configureStore } from '@reduxjs/toolkit';
import FAQReducer  from './states/reducers/FAQslice';
import AuthReducer from './states/reducers/AuthSlice';
import BrandReducer from './states/reducers/BrandSlice';

const store = configureStore({
  reducer: {
   faq: FAQReducer,
   auth:AuthReducer,
   brand:BrandReducer,
  },
});

export default store;
