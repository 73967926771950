import React, { useState } from 'react'
import Header from '../../Layout/Header/header';
import Sidebar from '../../Layout/Header/Sidebar';
import { Spin } from 'antd';
import Products from '../../components/ProductList/Products';
import ViewProducts from '../../components/ProductList/ViewProduct';
import StoreDetails from '../../components/StoreInformation/StoreDetails';

const StoreDetail = () => {
  const [loading, setLoading] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };
  return (
    <>
    <Header/>
    <Sidebar isCollapsed={isSidebarCollapsed} toggleCollapse={toggleCollapse} />
    <div className={`main-wrapper ${isSidebarCollapsed ? 'collapsed' : ''}`}>
        {loading ? (
          <div className="pre-loader">
            <Spin size="large" className="custom-loader" />
          </div>
        ) : (
            <StoreDetails/>
        )}
      </div>

    </>
  )
}

export default StoreDetail;

