import React, { useState, useEffect } from "react";
import { Input, Button, Spin } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { RiArrowLeftWideFill } from "react-icons/ri";
import bgLogo from "../../Assets/images/bg-logo.png";
import SetPassword from "./SetPassword";
import Instance from "../../AxiosConfig";
import "./Auth.css";

const VerifyCode = () => {
  const [code, setCode] = useState(new Array(6).fill(""));
  const [timer, setTimer] = useState(120);
  const [isNewPasswordPageVisible, setIsNewPasswordPageVisible] = useState(false);
  const [verificationResponse, setVerificationResponse] = useState("");
  const [verificationErrorResponse, setVerificationErrorResponse] = useState("");
  const [resendResponseMessage, setResendResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  useEffect(() => {
    console.log("Email prop received:", email);
    const countdown = setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(countdown);
  }, [email]);

  useEffect(() => {
    setCode(new Array(6).fill(""));
    setTimer(120);
    setVerificationErrorResponse("");
    setResendResponseMessage("");
  }, []);

  const handleBackToLogin = () => {
    navigate("/");
  };

  const handleChange = (e, index) => {
    const newCode = [...code];
    newCode[index] = e.target.value;
    setCode(newCode);
    if (e.target.value && index < code.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text");
    if (pasteData.length === code.length && /^\d+$/.test(pasteData)) {
      const newCode = pasteData.split("").slice(0, code.length);
      setCode(newCode);
      document.getElementById(`otp-input-${newCode.length - 1}`).focus();
    }
  };

  const handleResend = async () => {
    setTimer(120);
    setCode(new Array(6).fill(""));
    try {
      const response = await Instance.post("/api/v1/forgotPassword/verifyMail", {
        email,
      });
      console.log("Form submitted", response.data);
      setResendResponseMessage("OTP has been resent. Check your Mail.");
      setVerificationErrorResponse("");
    } catch (error) {
      console.error("Error during form submission:", error);
      if (error.response) {
        console.error("Error response:", error.response);
        let errorMessage = "Resend failed!";
        if (error.response.data && error.response.data.message) {
          const serverMessage = error.response.data.message;
          if (serverMessage.includes(":")) {
            errorMessage = serverMessage.split(":")[0];
          } else {
            errorMessage = serverMessage;
          }
        }
        setVerificationErrorResponse(errorMessage);
        setResendResponseMessage("");
      } else {
        setVerificationErrorResponse("Resend failed!");
        setResendResponseMessage("");
      }
    }
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    const verificationCode = code.join("");
    setLoading(true);
    try {
      const response = await Instance.post(
        `/api/v1/forgotPassword/verifyOtp/${email}`,
        {
          otp: verificationCode,
          email: email,
        }
      );
      console.log("Verification submitted", response.data);
      setVerificationResponse(response.data);
      setIsNewPasswordPageVisible(true);
    } catch (error) {
      console.error("Error during verification submission:", error);
      if (error.response && error.response.data && error.response.data.message) {
        setVerificationErrorResponse(error.response.data.message);
      } else {
        setVerificationErrorResponse("An error occurred during verification.");
      }
    }
    setLoading(false);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
  };

  return (
    <>
      {isNewPasswordPageVisible ? (
        <SetPassword email={email} />
      ) : (
        <div className="verify-code-container">
          <div className="col-lg-7"></div>
          <div className="col-lg-5">
            <div style={{ position: "fixed", top: "10px", right: "10px", padding: "20px" }}>
              <img src={bgLogo} alt="" style={{ width: "200px", height: "50px" }} />
            </div>
            <div className="forgot-details">
              <button onClick={handleBackToLogin} className="pb-4" style={{ border: "none", backgroundColor: "#fff", paddingLeft: "0px" }}>
                <RiArrowLeftWideFill style={{ fontSize: "20px" }} /> Back to login
              </button>
              <h2>Verify Code</h2>
              <p style={{ color: "#313131", fontSize: "15px" }}>An authentication code has been sent to your email.</p>
              <form className="login-form" onSubmit={handleContinue}>
                <label htmlFor="code" className="mt-4">Enter Code</label>
                <div className="code-inputs d-flex gap-4" onPaste={handlePaste}>
                  {code.map((otp, idx) => (
                    <Input
                      key={idx}
                      id={`otp-input-${idx}`}
                      type="text"
                      maxLength="1"
                      value={otp}
                      onChange={(e) => handleChange(e, idx)}
                      style={{ width: "50px", height: "50px", textAlign: "center" }}
                    />
                  ))}
                </div>
                <div className="timer d-flex justify-content-center">
                  <span style={{ color: "red" }}>{formatTime(timer)}</span>
                </div>
                {verificationErrorResponse && <p style={{ color: "red" }}>{verificationErrorResponse}</p>}
                {resendResponseMessage && <p style={{ color: "green" }}>{resendResponseMessage}</p>}
                <div className="d-flex button-container gap-4 pt-4">
                  <button className="login-btn" id="login-button" type="submit" disabled={loading}>
                    {loading ? <Spin /> : "Submit"}
                  </button>
                </div>
                <p className="text-center pt-2">
                  If you didn’t receive a code!
                  <Button type="link" onClick={handleResend} disabled={timer !== 0}>
                    Resend
                  </Button>
                </p>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyCode;
