import React from 'react';
import { MdPerson } from 'react-icons/md';
import { Rate } from 'antd';

const NotificationModelReview = ({ reviews }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    };

    return (
        <div className='scrollable-container notification-scrollbar-container'>
            <div className='container'>
                <div className='row'>
                    {reviews.length === 0 ? (
                        <p>No reviews available.</p>
                    ) : (
                        reviews.map((review, index) => (
                            <div key={index} className="col-12 notification-model-menu-divs">
                                <div className=''>
                                    <div className='notification-page-dot'></div>
                                </div>
                                <div>
                                    <p>New review for Product <span className='notification-model-menu-span'>  {review.title}</span> from brand 
                                    <span className='notification-model-menu-span'> {review.brand}</span> </p>
                                    <div className='notifiation-model-icon-div align-content-center'>
                                        <MdPerson className='notification-model-human-icon' style={{ fontSize: '30px', color: '#ED785E' }} />
                                        <p style={{marginBottom:"0",textAlign:"center"}}><span className='notification-model-menu-span'>{review.username}</span> rated your product  on {(review.createdDate)}</p>
                                    </div>
                                    <p style={{marginLeft:"40px" ,marginBottom:"0"}}>"{review.review}"</p>
                                    <Rate  style={{marginLeft:"40px"}} disabled defaultValue={review.rating} />
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default NotificationModelReview;
