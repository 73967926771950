import { createSlice } from '@reduxjs/toolkit';

export const BrandSlice = createSlice({
    name: 'brand',
    initialState: {
      BrandData: [],
    },
    reducers: {
      addBrands(state, action) {
        state.BrandData = action.payload;
      },
      addBrand: (state, action) => {
        console.log('Adding Brand with payload:', action.payload);
        state.BrandData.push({
          ...action.payload, 
          imageUrl: action.payload.imageUrl 
        });
        console.log('Updated BrandData:', state.BrandData);
      },
      removeBrand(state, action) {
        console.log('Removing Brand with brandId:', action.payload);
        state.BrandData = state.BrandData.filter(
          brand => brand.brandId !== action.payload
        );
        console.log('Updated BrandData:', state.BrandData);
      },
      editBrand(state, action) {
        const { brandId, ...updatedData } = action.payload;
        const index = state.BrandData.findIndex(brand => brand.brandId === brandId);
        if (index !== -1) {
          state.BrandData[index] = { ...state.BrandData[index], ...updatedData };
          console.log('Updated BrandData:', state.BrandData);
        }
      },
    },
  });
  
  export const { addBrand, removeBrand, addBrands, editBrand } = BrandSlice.actions;
  export default BrandSlice.reducer;
  