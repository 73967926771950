import React, { useState } from 'react'
import Sidebar from '../../Layout/Header/Sidebar';
import Header from '../../Layout/Header/header';
import Coupon from '../../components/Coupon/Coupon';
import { Spin } from 'antd';
import { Account } from '../../components/Account/Account';

const AccountPage = () => {
    const[loading,setLoading]=useState(false);
  return (
    <div>
        <Header/>
        <Sidebar/>
        <div className="main-wrapper">
        {loading ? (
          <div className="pre-loader">
            <Spin size="large" className="custom-loader" />
          </div>
        ) : (
            <Account/>

        )}
      </div>
    </div>
  )
}

export default AccountPage;
