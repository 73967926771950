import { useState, useEffect } from "react";
import "./header.css";
import profile from "../../Assets/images/singleuser.png";
import { FaRegBell } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { Input, message, List } from 'antd';
import { FiMessageCircle } from "react-icons/fi";
import { IoSearchOutline } from "react-icons/io5";
import { IoIosArrowDropdown } from "react-icons/io";
import { MdManageAccounts } from "react-icons/md";
import { LuRefreshCw } from "react-icons/lu";
import { TbLogout } from "react-icons/tb";
import { FaKey } from "react-icons/fa";
import NotificationModel from "../../components/NotificationModel/NotificationModel";
import Instance from "../../AxiosConfig";
export const allAdminRoutes = [
  {
    title: "Dashboard",
    description: "Overview of all activities",
    keyWords: ["dashboard", "overview", "home"],
    route: "/dashboard",
  },
  {
    title: "Coupon",
    description: "Manage and create coupons",
    keyWords: ["coupon", "discount", "manage coupons", "create coupon"],
    route: "/coupon",
  },
  {
    title: "Transaction",
    description: "View and manage transactions",
    keyWords: ["transactions", "payments", "manage transactions"],
    route: "/transaction",
  },
  {
    title: "Users",
    description: "Manage users and view user details",
    keyWords: ["users", "manage users", "user details"],
    route: "/Users",
  },
  {
    title: "Manage Orders",
    description: "Manage customer orders",
    keyWords: ["orders", "manage orders", "customer orders"],
    route: "/manage-orders",
  },
  
  {
    title: "Add Product",
    description: "Add a new product to the catalog",
    keyWords: ["add product", "new product"],
    route: "/add-product",
  },
  {
    title: "Customer Details",
    description: "View detailed customer information",
    keyWords: ["customer details", "user info", "customer info"],
    route: "/customer-details",
  },
  {
    title: "Products List",
    description: "Manage and view products",
    keyWords: ["products", "product list", "manage products"],
    route: "/products-list",
  },
  {
    title: "Feedback",
    description: "For customer feedback",
    keyWords: ["feedback", "", "clientfeedback"],
    route: "/feedback",
  },
  {
    title: "Store Information",
    description: "View Store information",
    keyWords: ["store", "store detail", "storeinformation"],
    route: "/store-information",
  },
  // {
  //   title: "Product Details",
  //   description: "View detailed product information",
  //   keyWords: ["product details", "view product", "product info"],
  //   route: "/product-details/:productListId",
  // },
  {
    title: "Settings",
    description: "Manage account settings",
    keyWords: ["settings", "account settings", "profile"],
    route: "/settings",
  },
  {
    title: "Brands",
    description: "Manage product brands",
    keyWords: ["brands", "manage brands", "product brands"],
    route: "/brands",
  },
  {
    title: "Manage Admins",
    description: "Manage admin accounts",
    keyWords: ["admins", "manage admins", "admin roles"],
    route: "/manage-admin",
  },
  // {
  //   title: "Customer Support",
  //   description: "Manage customer support and chat",
  //   keyWords: ["support", "customer support", "chat support"],
  //   route: "/chat-support",
  // },
  {
    title: "Admin Roles",
    description: "Manage roles and permissions",
    keyWords: ["roles", "admin roles", "permissions"],
    route: "/admin-roles",
  },
  {
    title: "Product Categories",
    description: "Manage product categories",
    keyWords: ["categories", "product categories", "manage categories"],
    route: "/ProductCategories",
  },
  {
    title: "FAQs",
    description: "Manage frequently asked questions",
    keyWords: ["faqs", "frequently asked questions", "help"],
    route: "/faqs",
  },
  {
    title: "Logout",
    description: "Logout from the system",
    keyWords: ["logout", "sign out", "exit"],
    route: "/logout",
  },
  {
    title: "Contact Us",
    description: "Get in touch with us",
    keyWords: ["contact", "support", "help", "contact us"],
    route: "/contact-us",
  },
  {
    title: "Account",
    description: "View and manage account details",
    keyWords: ["account", "profile", "account details"],
    route: "/account",
  },
  {
    title: "Review",
    description: "Manage reviews and ratings",
    keyWords: ["reviews", "ratings", "manage reviews"],
    route: "/review",
  },
  
];
const Header = () => {
  const navigate = useNavigate();
  const [notificationCount, setNotificationCount] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); 
  const [userData, setUserData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [imageData, setImageData] = useState("");

  const handleOpenAddPostModal = () => {
    setIsModalVisible(true); 
  };
  const handleDropdownToggle = () => {
    setIsDropdownOpen(prevState => !prevState);
  };
  const fetchUserData = async () => {
    try {
      const response = await Instance.get("/api/v1/user/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
          'Content-Type': 'application/json',
        }
      });
      if (response.status === 200) {
        // console.log("gyhhjh", response.data)
        setUserData(response.data);
        setImageData(response.data.imageData);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const fetchNotificationCount = async () => {
    try {
      const response = await Instance.get("/api/v1/notification/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
          "Content-Type": "application/json",
        }
      });
      if (response.status === 200) {
        const orders = response.data.orders || [];
        const ratingReviews = response.data.ratingReviews || [];
        const totalCount = orders.length + ratingReviews.length;
        setNotificationCount(totalCount);
      }
    } catch (error) {
      console.error("Error fetching notification count:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchNotificationCount();
  }, []);

  const profileImageSrc = imageData ? `data:image/png;base64,${imageData}` : (userData.imageUrl || profile);
  const handleSearch = (value) => {
    const searchQuery = value.toLowerCase().trim().replace(/\s+/g, "");
    setSearchQuery(searchQuery);

    if (!searchQuery || searchQuery === "") {
      setFilteredRoutes([]); 
      return;
  }

    const suggestions = allAdminRoutes.filter((route) =>
      route.keyWords.some((keyword) =>
        keyword.toLowerCase().replace(/\s+/g, "").includes(searchQuery)
      )
    );

    setFilteredRoutes(suggestions);

    if (suggestions.length === 0) {
      message.error("No matching page found");
    }
  };

  const handleSelect = (route) => {
    navigate(route);
    setSearchQuery("");
    setFilteredRoutes([]);
  };

  return (
    <div>
      <nav className="navbar-header">
        <div className="d-flex w-100 justify-content-between">
          <div style={{ marginLeft: "80px" }} className="mobile-search">
          <Input
        size="large"
        placeholder="Search Here"
        prefix={<IoSearchOutline />}
        style={{ width: "400px" }}
        className="header-search-bar"
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)} 
      />
      {filteredRoutes.length > 0 && (
        <List
          style={{
            marginTop: "10px",
            width: "400px",
            maxHeight: "200px",
            overflowY: "auto", 
            backgroundColor: "white", 
            borderRadius: "4px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            position: "absolute", 
            zIndex: 1, 
          }}
          bordered
          dataSource={filteredRoutes}
          renderItem={(item) => (
            <List.Item
              onClick={() => handleSelect(item.route)}
              style={{ cursor: "pointer" }}
            >
              {item.title}
            </List.Item>
          )}
        />
      )}
          </div>
          <div className="d-flex align-items-center gap-2">
            {/* <button
              type="button"
              onClick={handleNotificationClick}
              aria-controls="navbar-notification"
              aria-expanded="false"
              className="notification-button"
            >
              <FiMessageCircle className="notification-icon" />
            </button> */}
            <button
              type="button"
              onClick={handleOpenAddPostModal}
              aria-controls="navbar-notification"
              aria-expanded="false"
              className="notification-button"
            >
              <FaRegBell className="notification-icon" />
              <span className="notification-badge">{notificationCount}</span>
            </button>
            <NotificationModel isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}  setNotificationCount={setNotificationCount} />

            <div className="profile-photo-header">
              <img src={profileImageSrc} alt="Profile" />
            </div>

            <div className="d-flex align-items-center gap-2">
              <div className="user-info">
                <span
                  style={{
                    color: "#000",
                    fontSize: "16px",
                    fontWeight: "600",
                    letterSpacing: "0.5px",
                  }}
                >
                  {userData.fullName}
                </span>
                <br />
                <span className="xl-2">
                  {userData.roles && userData.roles.length > 0
                    ? userData.roles[0].roleName.replace(/_/g, ' ')
                    : "Role not found"}
                </span>
              </div>

              <button
                className="user-image"
                type="button"
                onClick={handleDropdownToggle}
                aria-controls="user-menu"
                aria-expanded={isDropdownOpen}
              >
                <IoIosArrowDropdown className="header-dropdown-icon" />
              </button>

              {isDropdownOpen && (
                <div className="dropdown-menusss">
                  <Link to="/settings" className="dropdown-items"><MdManageAccounts style={{ width: "18px", height: "18px" }} /> &nbsp; Profile</Link>
                  <Link to="/settings?section=password" className="dropdown-items"><FaKey style={{ width: "18px", height: "18px" }} /> &nbsp; Change Password</Link>
                  <Link to="/logout" className="dropdown-items"><TbLogout style={{ width: "18px", height: "18px" }} />&nbsp; Log out</Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
