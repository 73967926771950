import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Filler } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Filler);

const CardGraph = ({ data, label, width = "100%", height = "100%" }) => {
  const labels = ["W1", "W2", "W3", "W4", "W5"];
  const filledData = labels.map((label, index) => data[index] !== undefined ? data[index] : 0);

  const chartData = {
    labels: labels, 
    datasets: [
      {
        label: label,
        data: filledData,
        borderColor: '#1eb564',
        fill: true,
        tension: 0.4, 
        pointRadius: 2, 
        pointHoverRadius: 3, 
        borderWidth: 2,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }
          
          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0, 'rgba(30, 181, 100, 0.5)'); 
          gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');   

          return gradient;
        },
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(27, 58, 87, 0.7)',  // Customize tooltip background color
        titleFont: {
        size: 10,  // Font size for tooltip title
      },
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false, // Remove x-axis grid lines
        },
        ticks: {
          font: {
            size: 8, 
          },
        },
      },
      y: {
        display: true,
        beginAtZero: true,
        grid: {
          display: false, // Remove y-axis grid lines
        },
        ticks: {
          font: {
            size: 9, 
          },
        },
      },
    },
  };

  return (
    <div className="align-content-center" style={{ width: `${width}px`, height: `${height}px` }}>
        <Line data={chartData} options={options} style={{display:"flex",width:"150px",height:"75px",justifyContent:"right",justifyItems:"right"}} />
    </div>
  );
};

export default CardGraph;
