import React,{useState} from 'react'
import Header from '../../Layout/Header/header';
import Sidebar from '../../Layout/Header/Sidebar';
import { Spin } from 'antd';
import Users from '../../components/Users/UsersList';
import StoreInformations from '../../components/StoreInformation/StoreInformations';



const StoreInform = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };
  return (
    <>
    <Header/>
    <Sidebar isCollapsed={isSidebarCollapsed} toggleCollapse={toggleCollapse} />
    <div className={`main-wrapper ${isSidebarCollapsed ? 'collapsed' : ''}`}>
        {loading ? (
          <div className="pre-loader">
            <Spin size="large" className="custom-loader" />
          </div>
        ) : (
            <StoreInformations/>
            
        )}
      </div>

    </>
  )
}

export default StoreInform;
