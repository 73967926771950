import React, { useState } from "react";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Instance from "../../AxiosConfig";
import "./Auth.css";
import bgLogo from "../../Assets/images/bg-logo.png";
import "react-toastify/dist/ReactToastify.css";

const SetPassword = ({ email }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible1, setPasswordVisible1] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const navigate = useNavigate();

  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(!passwordVisible1);
  };

  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };

  const validatePassword = (password) => {
    // Password validation rules
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()]).{8,}$/;
    return regex.test(password);
  };

  const handlePasswordBlur = () => {
    console.log('Password:', password); // Check the password value
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
    } else if (!validatePassword(password)) {
      setPasswordError(
        "Password must be at least 6 characters long and contain at least one special character and one number"
      );
    } else {
      setPasswordError("");
    }
  };
  const handleConfirmPasswordBlur = () => {
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    handlePasswordBlur();
    handleConfirmPasswordBlur();

    if (passwordError || confirmPasswordError) {
      return;
    }

    try {
      await Instance.post(`/api/v1/forgotPassword/changePassword/${email}`, {
        password,
        confirmPassword,
        email,
      });
      toast.success("Password updated successfully!");

      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      console.error("Error during password update:", error);
      toast.error("Failed to update password. Please try again.");
    }
  };

  return (
    <div className="set-password-container">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="col-lg-7"></div>
      <div className="col-lg-5">
        <div style={{ position: "fixed", top: "10px", right: "10px", padding: "20px" }}>
          <img src={bgLogo} alt="" style={{ width: "200px", height: "50px" }} />
        </div>
        <div className="forgot-details">
          <h2>Set a Password</h2>
          <p style={{ color: "#313131", fontSize: "15px" }}>
            Your previous password has been reset. Please set a new password for your account.
          </p>
          <form className="login-form" onSubmit={handleUpdatePassword}>
            <label htmlFor="password1" className="mt-2">Create password</label>
            <div className="password-container">
              <input
                type={passwordVisible1 ? "text" : "password"}
                id="password1"
                name="password1"
                placeholder="Enter a password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={handlePasswordBlur}
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />
              <button
                type="button"
                className="password-toggle"
                onClick={togglePasswordVisibility1}
              >
                {passwordVisible1 ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </button>
            </div>
            {passwordError && <p className="error" style={{ color: "red" }}>{passwordError}</p>}
            <label htmlFor="password2" className="mt-2">Re-enter password</label>
            <div className="password-container">
              <input
                type={passwordVisible2 ? "text" : "password"}
                id="password2"
                name="password2"
                placeholder="Re-enter your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onBlur={handleConfirmPasswordBlur}
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />
              <button
                type="button"
                className="password-toggle"
                onClick={togglePasswordVisibility2}
                
              >
                {passwordVisible2 ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </button>
            </div>
            {confirmPasswordError && <p className="error" style={{ color: "red" }}>{confirmPasswordError}</p>}
            <div className="d-flex button-container gap-4 pt-4">
              <button className="login-btn" id="login-button" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
