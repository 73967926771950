import React from 'react'
import TotalOrders from './TotalOrders'
import SalesDetails from './SalesDetails'
import DealsDetails from './DealsDetails'

export const Dashboard = () => {
  return (
    <div className='container'>
      <div className="main-title-all">
        <div style={{ display: "flex", justifyContent: "start", marginBottom: "30px" }}>
          <span> Dashboard</span>
        </div>
      </div>
      <TotalOrders />
      <SalesDetails />
      <DealsDetails />
    </div>
  )
}
