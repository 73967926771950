import React, { useState } from "react";
import { Modal } from "antd";
import icon1 from "../../Assets/images/user-icon-1.png";
import { IoIosArrowDown } from "react-icons/io";
import { CiCircleAlert } from "react-icons/ci";
import { FaRegCopy } from "react-icons/fa6";

const ViewTransaction = ({ isModalVisible, setIsModalVisible }) => {
  const [loading, setLoading] = useState(false);
  const [isGrossSettlementVisible, setIsGrossSettlementVisible] = useState(false);
  const [isDeductionVisible, setIsDeductionVisible] = useState(false);


  const toggleGrossSettlement = () => {
    setIsGrossSettlementVisible(!isGrossSettlementVisible);
  };
  const toggleDeduction = () => {
    setIsDeductionVisible(!isDeductionVisible);
  };

  return (
    <>
      <Modal
        title="Details"
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={[null]}
      >
        <div >
         <div className="d-flex justify-content-between view-transaction-modal-div">
            <div>
                <h4>₹ 45,970 &nbsp;<span style={{ color:"#F97316",backgroundColor:"#fee7d7",padding:"2px",borderRadius:"5px",fontSize:"12px" }}>created</span> </h4>
                <p>Created on Fri June 24, 02:03pm</p>
            </div>
            <img src={icon1} className="icon-1-img" alt="" />
         </div>
         <div className="view-transaction-modal-div">
          <div className="d-flex justify-content-between">
            <p>Gross settlement <IoIosArrowDown onClick={toggleGrossSettlement} className={isGrossSettlementVisible ? 'rotate-icon' : ''}/></p>
            <p style={{color:"#28c76f"}}>₹ 34,989.00</p>
          </div>
          {isGrossSettlementVisible && (
          <div className="d-flex justify-content-between">
            <p>Payment <CiCircleAlert/></p>
            <p >₹ 34,989.00</p>
          </div>
          )}
          <div className="straight-line mb-2"></div>
          <div className="d-flex justify-content-between">
            <p>Deduction  <IoIosArrowDown onClick={toggleDeduction} className={isDeductionVisible ? 'rotate-icon' : ''}/></p>
            <p style={{color:"#ff0000"}}>₹ 34,989</p>
          </div>
          {isDeductionVisible && (
            <>
           
          <div className="d-flex justify-content-between">
            <p>Tax <CiCircleAlert/></p>
            <p >₹ 225.90</p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Fee <CiCircleAlert/></p>
            <p >₹ 644.23</p>
          </div>
          </>
          )}
          <div className="straight-line mb-2"></div>
          <div className="d-flex justify-content-between">
            <p>Net Settlements</p>
            <p>₹ 34,118.87</p>
          </div>

          <div className="view-transaction-modal-div">
            <h5>Details</h5>
            <div className="d-flex gap-5">
            <p>Settlement ID</p>
            <p>setl_Oh1jfnnrgirg <FaRegCopy/></p>
          </div>
          <div className="d-flex gap-5">
            <p>UTR number</p>
            <p>cpc2jgindngin </p>
          </div>
          </div>
         </div>

        </div>
      </Modal>
    </>
  );
};

export default ViewTransaction;
