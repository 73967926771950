import React,{useState} from "react";
import { IoFilter } from "react-icons/io5";
import { Table,Input,DatePicker } from "antd";
import "./Account.css";
import { SearchOutlined } from "@ant-design/icons";
import Select from "react-select"
import { MdOutlineCalendarMonth } from "react-icons/md";
import ViewTransaction from "./ViewTransaction";


export const AccountRecentTransactions = () => {
    const [searchText, setSearchText] = useState("");
    const StatusOptions = [
        { value: "processed", label: "Processed" },
        { value: "cancelled", label: "Cancelled" },
        { value: "created", label: "Created" },
      ];
      const [isModalVisible, setIsModalVisible] = useState(false);
      const handleOpenAddPostModal = () => {
        setIsModalVisible(true); // Open modal
      };
    
      const dummyData = [
        {
          createdOn: "Jun 27 2024, 02:00 PM",
          settlementId: "settl_0hjfnmgrjrg",
          utrNumber: "cpc2gjdndgnjn",
          netSettlement: "₹ 86,989",
          status: "Processed",
        },
        {
          createdOn: "Jun 27 2024, 02:00 PM",
          settlementId: "settl_0hjfnmgrjrg",
          utrNumber: "cpc2gjdndgnjn",
          netSettlement: "₹ 86,989",
          status: "Processed",
        },
        {
          createdOn: "Jun 27 2024, 02:00 PM",
          settlementId: "settl_0hjfnmgrjrg",
          utrNumber: "cpc2gjdndgnjn",
          netSettlement: "₹ 98,907",
          status: "Cancelled",
        },
        {
          createdOn: "Jun 27 2024, 02:00 PM",
          settlementId: "settl_0hjfnmgrjrg",
          utrNumber: "cpc2gjdndgnjn",
          netSettlement: "₹ 45,970",
          status: "Created",
        },
        {
          createdOn: "Jun 27 2024, 02:00 PM",
          settlementId: "settl_0hjfnmgrjrg",
          utrNumber: "cpc2gjdndgnjn",
          netSettlement: "₹ 67,090",
          status: "Processed",
        },
        {
          createdOn: "Jun 27 2024, 02:00 PM",
          settlementId: "settl_0hjfnmgrjrg",
          utrNumber: "cpc2gjdndgnjn",
          netSettlement: "₹ 34,798",
          status: "Cancelled",
        },
        {
          createdOn: "Jun 27 2024, 02:00 PM",
          settlementId: "settl_0hjfnmgrjrg",
          utrNumber: "cpc2gjdndgnjn",
          netSettlement: "₹ 56,989",
          status: "Cancelled",
        },
        {
          createdOn: "Jun 27 2024, 02:00 PM",
          settlementId: "settl_0hjfnmgrjrg",
          utrNumber: "cpc2gjdndgnjn",
          netSettlement: "₹ 23,909",
          status: "Processed",
        },
      ];
    
      const columns = [
        {
          title: "CREATED ON",
          dataIndex: "createdOn",
        },
        {
          title: "SETTLEMENT ID",
          dataIndex: "settlementId",
        },
        {
          title: "UTR NUMBER",
          dataIndex: "utrNumber",
        },
        {
          title: "NET SETTLEMENT",
          dataIndex: "netSettlement",
        },
        {
          title: "STATUS",
          dataIndex: "status",
          render: (status) => {
            let color = "";
            let backgroundColor="";
            let padding="";
            if (status === "Processed") {
              color = "#28C76F";
              backgroundColor="#ddf6e8";
            } else if (status === "Created") {
              color = "#F97316";
              backgroundColor="#fee7d7";
            } else if (status === "Cancelled") {
              color = "#FF0000";
              backgroundColor="#fde0e0";
            }
            return <span style={{ color,backgroundColor,padding:"4px",borderRadius:"5px",width:"50px" }}>{status}</span>;
          },
        },
        {
          title: "INFO",
          dataIndex: "info",
          render: () => <button className="accounts-view-button"      onClick={handleOpenAddPostModal}>View</button>,
        },
      ];
    
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid #E5E5E5",
      borderRadius: "4px",
      boxShadow: "none",
      cursor: "pointer",
      color: "#ce1b28",
    }),
  };
  return (
      <div>
      <div className=" account-text d-flex justify-content-between mt-5">
        <h5>Recent Transaction</h5>
        <div className="d-flex gap-2  select-media">
          <div className="select-options ">
           <Input
              placeholder="Search by utr ,settle."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="search-input-table me-2"
              prefix={<SearchOutlined />}
            />
          </div>

         <div className="search-table-container">
          <Select
            options={StatusOptions}
            isMulti
            placeholder="Status"
            styles={{ ...customStyles, menuPortal: document.body }}
          />
        </div>
        <button className="calender-button-account"><MdOutlineCalendarMonth style={{color:"#8b909a",fontSize:"20px"}}/></button>
          <button className="filters-button">Apply</button>
          <button className="reset-button">Reset</button>
        </div>
      </div>
      <div className="table-list mt-4">
        <Table
          className="table-data"
          columns={columns}
          dataSource={dummyData}
          rowKey={(data) => data.products}
          // onRow={() => ({
          //   onClick: handleRowClick,
          // })}
        />
      </div>
      <ViewTransaction
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
    </div>
  );
};
