import React, { useState, useEffect } from 'react';
import BrandProducts from './BrandProducts';
import { AudioOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { FaPlus } from 'react-icons/fa';
import { Input, Spin } from 'antd';
import "./Brands.css";
import AddBrands from './AddBrands';
import Select from "react-select";
import Instance from '../../AxiosConfig'; 

const { Search } = Input;

export const Brands = () => {
    const [isModalVisible, setIsModalVisible] = useState(false); 
    const [selectedCategory, setSelectedCategory] = useState(null); 
    const [categories, setCategories] = useState([]); 
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const navigate = useNavigate();

    const handleOpenAddPostModal = () => {
        setIsModalVisible(true); 
    };

    useEffect(() => {
        const getProductCategory = async () => {
            setLoading(true);
            try {
                const url = `/api/v1/categories/`;
                const response = await Instance.get(url);
                if (response.status === 200) {
                    console.log("Categories fetched:", response.data);
                    const formattedCategories = response.data.map(category => ({
                        value: category.categoryTitle,  // Assuming categoryTitle should be used as the category identifier
                        label: category.categoryTitle,
                    }));
                    setCategories(formattedCategories); 
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        getProductCategory();
    }, []);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            border: "1px solid #E5E5E5",
            borderRadius: "4px",
            boxShadow: "none",
            cursor: "pointer",
            color: "#ce1b28",
        }),
    };

    const handleCategoryChange = (selectedOption) => {
        setSelectedCategory(selectedOption ? selectedOption.value : null);
    };

    if (loading) {
        return <div className="loading"><Spin size="large" /></div>; // Use Spin for loading indicator
      }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className='container'>
            <div className="d-flex justify-content-between brands-media">
                <div className="main-title-all">
                    <span>Brands</span>
                    <div className="search-table-container mt-2">
                        <Select
                            options={categories}
                            placeholder="Categories"
                            styles={{ ...customStyles, menuPortal: document.body }}
                            onChange={handleCategoryChange}
                            className="select-category-brands"
                        />
                    </div>
                </div>
                <div className="d-flex gap-4 search-media">
                    {/* <div className="search-brand-card p-4">
                        <Search
                            placeholder="search Products"
                            onSearch={value => console.log(value)}
                            style={{ width: 300 }}
                            className="brand-search"
                        />
                    </div> */}
                    <div>
                        <button onClick={handleOpenAddPostModal} className='add-brands-button'><FaPlus /> &nbsp; Add Brands</button>
                    </div>
                </div>
            </div>
            <AddBrands isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
            <BrandProducts categoryName={selectedCategory} />
        </div>
    );
};
