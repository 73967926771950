import React, { useState,useEffect } from 'react'
import Header from '../../Layout/Header/header';
import Sidebar from '../../Layout/Header/Sidebar';
import { Spin } from 'antd';
import ProductCategories from '../../components/ProductCategories/ProductCategories';


const ProductCategoriesPage = () => {
  const [loading, setLoading] = useState(true)
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const toggleCollapse = () => {
     setIsSidebarCollapsed(!isSidebarCollapsed);
   };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Header />
      <Sidebar isCollapsed={isSidebarCollapsed} toggleCollapse={toggleCollapse} />
    <div className={`main-wrapper ${isSidebarCollapsed ? 'collapsed' : ''}`}>
        {loading ? (
          <div className="pre-loader">
            <Spin size="large" className="custom-loader" />
          </div>
        ) : (
          <ProductCategories />
        )}
      </div>
      
    </div>
  )
}

export default ProductCategoriesPage;
