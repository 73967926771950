import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  jwtToken: localStorage.getItem('adminAuthToken') || null,
  roleAccess: JSON.parse(localStorage.getItem('roleAccess')) || [], 
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.jwtToken = action.payload.jwtToken;
      state.roleAccess = action.payload.roleAccess;

      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('adminAuthToken', action.payload.jwtToken);
      localStorage.setItem('roleAccess', JSON.stringify(action.payload.roleAccess));
    },
    logout: (state) => {
      state.user = null;
      state.jwtToken = null;
      state.roleAccess = [];

      localStorage.removeItem('user');
      localStorage.removeItem('adminAuthToken');
      localStorage.removeItem('roleAccess');
    },
  },
});

export const { loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
