import React, { useState, useEffect } from "react";
import { Table, Input, Tag, Dropdown, Menu, Pagination, message, Select, DatePicker, Spin, Modal, Carousel, Image } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { IoMdPrint, IoIosArrowDown } from "react-icons/io";
import Instance from "../../AxiosConfig";
import moment from "moment";
import { Option } from "antd/es/mentions";
import jsPDF from "jspdf";
import InvoiceLogo1 from '../../Assets/images/white-bg-logo.png'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';



const bulkActions = [
  { value: "COMPLETED", label: "Completed" },
  { value: "PENDING", label: "Pending" },
  { value: "PROCESSING", label: "Processing" },
  { value: "CANCELLED", label: "Cancelled" },
];

const TransactionList = () => {
  const [searchText, setSearchText] = useState("");
  const [modalImageSrc, setModalImageSrc] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const handlePreviewImage = (src) => {
    setPreviewImage(src);
    setPreviewOpen(true);
  };
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const [expandedNestedRowKeys, setExpandedNestedRowKeys] = useState([]);


  const navigate = useNavigate();

  useEffect(() => {
    fetchOrdersData();
  }, []);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: filteredData?.length,
      // current:1,
    }));
  }, [searchText, selectedStatus, selectedDate, data]);




  const handleExportRows = (record) => {
    console.log("pdfdata:", record);
    const doc = new jsPDF();

    const tableData = (record.orderItems || []).map((item) => [
      item.title,
      `${item.totalPrice}`,
      item.quantity,
      item.orderItems[0].brand,
      `${item.orderItems[0].discountPercentage}%`,
      `${item.totalDiscountedPrice}`,
    ]);

    const tableHeaders = [
      "PRODUCT NAME",
      "PRICE",
      "QTY",
      "BRAND",
      "DISCOUNT",
      "TOTAL",
    ];

    doc.setFillColor("#fff");
    doc.rect(0, 0, doc.internal.pageSize.width, 40, "F");

    // const logoImage = new Image();
    // logoImage.src = InvoiceLogo1;

    // logoImage.onload = function () {
    doc.addImage(InvoiceLogo1, "PNG", 15, 10, 50, 20);

    doc.setFontSize(11);
    doc.setTextColor(80);

    const baseX = 95;
    const baseY = 12;
    const lineHeight = 7;

    const labelX = 135;
    const valueX = 165;

    const paymentDate = record.paymentDetails?.paymentCreatedAt || record.orderDate;
    const formattedPaymentDate = paymentDate
      ? new Date(paymentDate).toLocaleDateString('en-us', { day: 'numeric', month: 'short', year: 'numeric' })
      : "N/A";

    doc.text(`Order ID:`, labelX, baseY);
    doc.text(`Name:`, labelX, baseY + lineHeight);
    doc.text(`Date:`, labelX, baseY + 2 * lineHeight);
    doc.text(`Payment Status:`, labelX, baseY + 3 * lineHeight);

    doc.text(`${record.orderId}`, valueX, baseY);
    doc.text(`${record.username}`, valueX, baseY + lineHeight);
    doc.text(`${formattedPaymentDate}`, valueX, baseY + 2 * lineHeight);
    doc.text(`${record.paymentDetails.paymentStatus}`, valueX, baseY + 3 * lineHeight);


    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const borderMargin = 7;

    doc.rect(borderMargin, borderMargin, pageWidth - 2 * borderMargin, pageHeight - 2 * borderMargin);

    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
      startY: 40,
      headStyles: {
        fillColor: [27, 58, 87],
        textColor: [255, 255, 255],
      },
      styles: {
        cellPadding: 4,
        minCellHeight: 8,
        lineWidth: 0.2,
        lineColor: [200, 200, 200],
      },
      columnStyles: {
        0: { cellWidth: 37 },
        3: { cellWidth: 37 },
        4: { cellWidth: 29 },
      },
    });


    const processTableData = (data) => {
      let previousBrand = '';
      let rowspan = 1;
      const processedData = [];

      data.forEach((row, index) => {
        if (row[0] === previousBrand) {
          rowspan++;
        } else {
          if (previousBrand) {
            processedData.push([previousBrand, '', '', '', '', '', '']); // placeholder for the merged row
          }
          previousBrand = row[0];
          rowspan = 1;
        }

        const newRow = [...row];
        newRow[0] = rowspan === 1 ? previousBrand : ''; // Clear the brand cell if it's not the first row of the rowspan
        processedData.push(newRow);
      });

      if (previousBrand) {
        processedData.push([previousBrand, '', '', '', '', '', '']); // Add the last brand
      }

      return processedData;
    };

    const steelTableData = (record.orderItems || []).flatMap(item =>
      item.orderItems
        .filter(nestedItem => nestedItem.steelProduct)
        .sort((a, b) => parseFloat(a.steelSize) - parseFloat(b.steelSize))
        .map(nestedItem => [
          nestedItem.brand,
          nestedItem.title,
          nestedItem.steelSize,
          `${(nestedItem.price ?? 0).toFixed(2)}`,
          `${nestedItem.discountPercentage}%`,
          nestedItem.quantity,
          `${(nestedItem.discountedPrice ?? 0).toFixed(2)}`,
        ])
    );

    const processedSteelTableData = processTableData(steelTableData);

    let lastBrand = '';
    for (let i = 0; i < steelTableData?.length; i++) {
      if (steelTableData[i][0] === lastBrand) {
        steelTableData[i][0] = '';
      } else {
        lastBrand = steelTableData[i][0];
      }
    }

    if (steelTableData?.length > 0) {
      const steelTableHeaders = [
        "BRAND",
        "PRODUCT NAME",
        "STEEL SIZE",
        "PRICE",
        "DISC %",
        "QTY",
        "DISC PRICE",
      ];

      doc.autoTable({
        head: [steelTableHeaders],
        body: steelTableData,
        startY: doc.lastAutoTable.finalY + 10,
        headStyles: {
          fillColor: [27, 58, 87],
          textColor: [255, 255, 255],
        },
        styles: {
          cellPadding: 4,
          minCellHeight: 8,
          lineWidth: 0.2,
          lineColor: [200, 200, 200],
        },
        columnStyles: {
          0: { cellWidth: 30 },
          2: { cellWidth: 20 },
          4: { cellWidth: 19 },
        },

      });
    }

    const finalY = doc.lastAutoTable.finalY;
    const labelMarginLeft = 140;
    const valueMarginLeft = 177;

    const labelMarginLeft2 = 14;
    const valueMarginLeft2 = 41;

    doc.setFontSize(12);
    doc.setTextColor(80);

    doc.text("Subtotal:", labelMarginLeft, finalY + 10);
    doc.text("Discount Amount:", labelMarginLeft, finalY + 20);
    if (record.couponApplied) {
      doc.text("Coupon Savings:", labelMarginLeft, finalY + 30);
    }
    doc.text("Shipping:", labelMarginLeft, finalY + 30 + (record.couponApplied ? 10 : 0));
    doc.line(labelMarginLeft, finalY + 33 + (record.couponApplied ? 10 : 0), valueMarginLeft + 20, finalY + 33 + (record.couponApplied ? 10 : 0));
    doc.text("Total Amount:", labelMarginLeft, finalY + 40 + (record.couponApplied ? 10 : 0));
    if (record.paymentType == "COD") {
      doc.text(`Amount Received:`, labelMarginLeft, finalY + 50 + (record.couponApplied ? 10 : 0));
      doc.text(`Balance Amount:`, labelMarginLeft, finalY + 60 + (record.couponApplied ? 10 : 0));
    }



    doc.text(`${(record.totalPrice ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 10);
    doc.text(`-${(record.discount ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 20);
    if (record.couponApplied) {
      doc.text(`-${(record.couponDiscountedPrice ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 30);
    }
    doc.text(`${(record.deliveryFee ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 30 + (record.couponApplied ? 10 : 0));
    doc.text(`${(record.totalPriceIncludeDeliveryFee ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 40 + (record.couponApplied ? 10 : 0));
    if (record.paymentType == "COD") {
      doc.text(`-${(record.paymentDetails.amountReceived ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 50 + (record.couponApplied ? 10 : 0));
      doc.text(`${((record.totalPriceIncludeDeliveryFee - record.paymentDetails.amountReceived) ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 60 + (record.couponApplied ? 10 : 0));
    }

    doc.setFontSize(11);
    const verticalOffset = record.paymentType === "COD" ? 10 : 0;
    const maxWidth = 90;
    const initialY = finalY + 10;
    let adjustedY = initialY;

    if (record.shippingAddress.gstNumber) {
      doc.text("GST Number:", labelMarginLeft2, adjustedY);
      doc.text(`${record.shippingAddress.gstNumber}`, valueMarginLeft2, adjustedY, { maxWidth });
      adjustedY += 10;
    }

    doc.text("Delivery To:", labelMarginLeft2, adjustedY);
    doc.text(`${record.shippingAddress.addressType}`, valueMarginLeft2, adjustedY, { maxWidth });
    adjustedY += 10;

    doc.text("Payment Type:", labelMarginLeft2, adjustedY);
    doc.text(`${record.paymentType}`, valueMarginLeft2, adjustedY, { maxWidth });
    adjustedY += 10;

    if (record.paymentType === "COD") {
      doc.text("Payment Id:", labelMarginLeft2, adjustedY);
      doc.text(`${record.paymentDetails.paymentId}`, valueMarginLeft2, adjustedY, { maxWidth });
      adjustedY += 10;
    }

    doc.text("Mobile No:", labelMarginLeft2, adjustedY);
    doc.text(`+91 ${record.shippingAddress.mobile}`, valueMarginLeft2, adjustedY, { maxWidth });
    adjustedY += 8 + verticalOffset;

    const lineHeight1 = 1.5;
    doc.text("Address:", labelMarginLeft2, adjustedY);
    doc.text(
      `${record.shippingAddress.streetAddress}\n${record.shippingAddress.address}\n${record.shippingAddress.city}, ${record.shippingAddress.state}, ${record.shippingAddress.zipCode} ${record.shippingAddress.country}`,
      valueMarginLeft2,
      adjustedY,
      { maxWidth, lineHeightFactor: lineHeight1 }
    );



    const totalPages = doc.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(`Page ${i} of ${totalPages}`, pageWidth - 20, pageHeight - 1, {
        align: 'right',
      });
    }

    // const pdfBase64 = doc.output('datauristring');
    // const newWindow = window.open();
    // newWindow.document.write(`<iframe width="100%" height="100%" src="${pdfBase64}"></iframe>`);
    const fileName = `Order_${record.orderId}.pdf`;
    doc.save(fileName);
    
  };


  const groupOrderItemsByProductId = (orders) => {
    return orders.map(order => {
      const groupedItems = order.orderItems.reduce((acc, item) => {
        const key = item.productId;

        if (!acc[key]) {
          acc[key] = {
            orderId: item.orderId,
            productId: item.productId,
            title: item.title,
            discountPercentage: item.discountPercentage,
            totalPrice: 0,
            totalDiscountedPrice: 0,
            quantity: 0,
            orderItems: [],
            steelProduct: item.steelProduct
          };
        }

        acc[key].quantity += item.quantity;
        acc[key].orderItems.push(item);
        acc[key].totalPrice += item.price;
        acc[key].totalDiscountedPrice += item.discountedPrice;

        if (acc[key].steelProduct && acc[key].totalPrice > 0) {
          const discount = ((acc[key].totalPrice - acc[key].totalDiscountedPrice) / acc[key].totalPrice) * 100;
          acc[key].discountPercentage = discount.toFixed(2);
        }

        return acc;
      }, {});

      return {
        ...order,
        orderItems: Object.values(groupedItems),
      };
    });
  };




  const fetchOrdersData = async () => {
    setLoading(true);
    try {
      const url = "/api/v1/admin/orders/all";
      const response = await Instance.get(url);
      if (response.status === 200 || response.status === 201) {
        const processedData = groupOrderItemsByProductId(response.data);
        setData(processedData);
        setPagination((prev) => ({
          ...prev,
          total: response.data?.length,
          current: prev.current,
        }));
        console.log("response", response.data);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
    setLoading(false);
  };

  const handleTableChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
      pageSize,
    }));
  };

  const handlePageSizeChange = (current, size) => {
    setPagination((prev) => ({
      ...prev,
      current,
      pageSize: size,
    }));
  };

  const handleToggleDetails = (record) => {
    const key = record.orderId;
    setExpandedRowKeys((prevKeys) =>
      prevKeys.includes(key) ? prevKeys.filter((k) => k !== key) : [...prevKeys, key]
    );
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value || []);

    setPagination((prev) => ({
      ...prev,
      current: 1,
    }));
  };


  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString);
    setPagination((prev) => ({
      ...prev,
      current: 1,
    }));
  };

  useEffect(() => {
    if (selectedStatus?.length > 0 || selectedDate || searchText) {
      setPagination((prev) => ({
        ...prev,
        current: 1, // Reset to the first page
      }));
    }
  }, [selectedStatus, selectedDate, searchText]);

  const filteredData = data
    .filter((item) => {
      if (selectedStatus?.length > 0) {
        return selectedStatus.includes(item.paymentDetails.paymentStatus);
      }
      return true;
    })
    .filter((item) => {
      if (selectedDate) {
        return moment(item.orderDate).isSame(selectedDate, "day");
      }
      return true;
    })
    .filter((item) => {
      if (searchText) {
        return item.orderId.toString().includes(searchText);
      }
      return true;
    });

  const paginatedData = filteredData.slice(
    (pagination.current - 1) * pagination.pageSize,
    pagination.current * pagination.pageSize
  );

  const handleStatusChange2 = async (value, record) => {
    setLoading(true);
    try {
      console.log("Changing status for order:", record.orderId);
      console.log("New status:", value);

      const url = `/api/v1/admin/orders/payment-status/${record.orderId}?status=${value}`;
      console.log("Request URL:", url);

      const response = await Instance.put(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
          },
        }
      );
      console.log("Response status:", response.status);

      if (response.status === 200 || response.status === 201) {
        setData((prevData) =>
          prevData.map((order) =>
            order.orderId === record.orderId
              ? {
                ...order,
                paymentDetails: {
                  ...order.paymentDetails,
                  paymentStatus: value.toUpperCase()
                }
              }
              : order
          )
        );
        fetchOrdersData();

        message.success("Payment status updated successfully");
      } else {
        message.error("Failed to update order status");
      }
    } catch (error) {
      console.error("Failed to update order status:", error);
      message.error("Failed to update order status. Please try again later.");
    }
    setLoading(false);
  };

  const handleNestedToggleDetails = (productId) => {
    setExpandedNestedRowKeys((prevKeys) => {
      // Ensure prevKeys is always an array
      const keys = prevKeys || [];
      return keys.includes(productId)
        ? keys.filter(key => key !== productId)
        : [...keys, productId];
    });
  };

  const showModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "imageData",
      render: (text, record) => (
        <>
          {record.imageData && (
            <div
              onClick={() => showModal([
                `data:image/png;base64,${record.imageData}`,
                record.imageData1 ? `data:image/png;base64,${record.imageData1}` : null,
                record.imageData2 ? `data:image/png;base64,${record.imageData2}` : null,
              ].filter(Boolean))}
              style={{ cursor: 'pointer' }}
            >
              <img
                src={`data:image/png;base64,${record.imageData}`}
                alt="Product"
                style={{ width: '70px', height: '50px' }}
              />
            </div>
          )}
          {!record.imageData && <div style={{ width: '80px', height: 'auto' }} />}
        </>
      ),
    },
    {
      title: "Id",
      dataIndex: "orderId",
      render: (text) => `#${text.toString().slice(-4)}`,
    },
    {
      title: "Customer",
      dataIndex: "username",
    },
    {
      title: "Date",
      dataIndex: ["paymentDetails", "paymentCreatedAt"],
      sorter: (a, b) => {
        const dateA = a.paymentDetails?.paymentCreatedAt ? new Date(a.paymentDetails.paymentCreatedAt) : new Date(a.orderDate);
        const dateB = b.paymentDetails?.paymentCreatedAt ? new Date(b.paymentDetails.paymentCreatedAt) : new Date(b.orderDate);
        return dateA - dateB;
      },
      render: (text, record) => {
        const displayDate = text || record.orderDate; // Fallback to orderDate if paymentCreatedAt is null
        return displayDate
          ? new Date(displayDate).toLocaleDateString('en-us', { day: 'numeric', month: 'short', year: 'numeric' })
          : "N/A"; // If no date is available, display "N/A"
      },
    },
    {
      title: "Total",
      dataIndex: "totalPriceIncludeDeliveryFee",
      render: (text) => `₹ ${parseFloat(text).toFixed(2)}`,
    },
    {
      title: "Delivery Fee",
      dataIndex: "deliveryFee",
      render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"),
    },
    {
      title: "Payment  Method",
      dataIndex: ["paymentDetails", "paymentMethod"],
      render: (method) => method?.toUpperCase() || 'NA',
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
    },
    {
      title: "Payment Status",
      dataIndex: ["paymentDetails", "paymentStatus"],
      render: (status, record) => {
        let color = "";
        let backgroundColor = "";
        if (status === "COMPLETED") {
          color = "#006400";
          backgroundColor = "#E0F8E0";
        } else if (status === "PENDING") {
          color = "#FF8C00";
          backgroundColor = "#FFE5B4";
        } else if (status === "CANCELLED") {
          color = "#8B0000";
          backgroundColor = "#FAD4D4";
        } else if (status === "PROCESSING") {
          color = "#0044CC";
          backgroundColor = "#E0F0FF";
        }
        return (
          <div style={{ width: "110px", backgroundColor, padding: "4px", borderRadius: "5px" }}>
            <span style={{ color, justifyContent: "center", display: "flex" }}>{record.paymentDetails.paymentStatus}</span>
          </div>
        );
      },
    },
    {
      title: "Update Status",
      dataIndex: "status",
      render: (status, record) => (
        <Select
          value={status}
          onChange={(value) => handleStatusChange2(value, record)}
          className="custom-select-status"
          placeholder="Select Status"
        >
          <Option value="Pending">PENDING</Option>
          <Option value="Processing">PROCESSING</Option>
          <Option value="Completed">COMPLETED</Option>
          <Option value="Cancelled">CANCELLED</Option>
        </Select>
      ),
    },
    {
      title: "Exp",
      dataIndex: "expand",
      render: (_, record) => (
        <MdOutlineArrowDropDownCircle
          onClick={() => handleToggleDetails(record)}
          style={{ fontSize: "24px", cursor: "pointer", transform: expandedRowKeys?.includes(record.orderId) ? 'rotate(180deg)' : 'rotate(0deg)' }}
        />
      ),
    },
    {
      title: (
        <>
          <IoMdPrint
            style={{ fontSize: "20px", verticalAlign: "middle", marginLeft: "4px" }}
          />{" "}
          Print
        </>
      ),
      dataIndex: "orderId",
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => handleExportRows(record)}>
                <IoMdPrint style={{ marginRight: "8px" }} />
                Print
              </Menu.Item>
            </Menu>
          }
        >
          <BsThreeDots style={{ fontSize: "24px" }} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="user-list-section">
        <div className="main-title-all">
          <div style={{ display: "flex", justifyContent: "start", marginBottom: "30px" }}>
            <span>Transaction </span>
          </div>
        </div>
        <div className="filter-row">
          <div className="select-options media-select-transaction">
            <Input
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="search-input-table me-2"
              prefix={<SearchOutlined />}
            />
            <Select
              options={[...bulkActions]}
              mode="multiple"
              placeholder="Status"
              // value={selectedStatus}
              className="status-option"
              // styles={{ ...customStyles, menuPortal: document.body }}
              onChange={handleStatusChange}
            />
          </div>
          <div className="search-table-container">
            <DatePicker
              placeholder="Filter by Date"
              format="YYYY-MM-DD"
              value={selectedDate ? moment(selectedDate) : null}
              onChange={handleDateChange}
            />
          </div>
        </div>
        <div className="table-list ">
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Spin size="large" />
            </div>
          ) : (
            <>
              <Table
                className="table-data"
                columns={columns}
                dataSource={paginatedData}
                rowKey="orderId"
                pagination={false}
                loading={loading}
                expandable={{
                  expandedRowRender: (record) => (
                    <div className="custom-child-table">
                      <Table
                        className="manageOrder-main-table"
                        dataSource={record.orderItems}
                        columns={[
                          { title: 'ORDERID', dataIndex: 'orderId', render: (text) => `#${text}` },
                          { title: "NAME", dataIndex: "title", key: "title" },
                          { title: "PRICE", dataIndex: "totalPrice", key: "totalPrice", render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"), },
                          { title: "QUANTITY", dataIndex: "quantity", key: "quantity" },
                          {
                            title: "DISCOUNT %",
                            dataIndex: "discountPercentage",
                            key: "discountPercentage",
                            render: (text) => `${text}%`,
                          },
                          { title: "TOTAL", dataIndex: "totalDiscountedPrice", key: "totalDiscountedPrice", render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"), },
                          {
                            title: "EXPAND",
                            dataIndex: "expand",
                            render: (_, itemRecord) => (
                              itemRecord.steelProduct ? (
                                <MdOutlineArrowDropDownCircle
                                  onClick={() => handleNestedToggleDetails(itemRecord.productId)} // Use productId here
                                  style={{
                                    fontSize: "24px",
                                    cursor: "pointer",
                                    transform: expandedNestedRowKeys.includes(itemRecord.productId) ? 'rotate(180deg)' : 'rotate(0deg)'
                                  }}
                                />
                              ) : null
                            ),
                          },
                        ]}
                        rowKey="productId"
                        pagination={false}
                        expandable={{
                          expandedRowRender: (nestedRecord) => (
                            <Table
                              dataSource={nestedRecord.orderItems}
                              columns={[
                                {
                                  title: "STEEL SIZE",
                                  dataIndex: "steelSize",
                                  key: "steelSize",
                                  sorter: (a, b) => parseFloat(b.steelSize) - parseFloat(a.steelSize),
                                  defaultSortOrder: 'descend', // Sorts in descending order by default
                                },
                                { title: "PRICE", dataIndex: "price", key: "price", render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"), },
                                {
                                  title: "DISCOUNT %",
                                  dataIndex: "discountPercentage",
                                  key: "discountPercentage",
                                  render: (text) => `${text}%`,
                                },
                                { title: "DISCOUNT PRICE", dataIndex: "discountedPrice", key: "discountedPrice", render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"), },
                                { title: "QUANTITY", dataIndex: "quantity", key: "quantity" },

                              ]}
                              rowKey="steelProductId"
                              pagination={false}
                            />
                          ),
                          rowExpandable: (nestedRecord) => !!nestedRecord.steelProduct,
                          expandedRowKeys: expandedNestedRowKeys,
                          onExpand: (expanded, nestedRecord) => {
                            handleNestedToggleDetails(nestedRecord.productId); // Use productId here
                          },
                        }}
                      />
                      <div className="row">
                        <div className="col-lg-7 manageorders-media"
                          style={{ marginTop: 16, display: "flex", gap: "10px" }}>
                          <div className="address-label">
                          {record.shippingAddress.gstNumber && (
                              <p>GST Number</p>
                            )}
                            <p>Delivery To</p>
                            <p style={{ marginTop: -10 }}>PaymentType</p>
                            {record.paymentType == "COD" && <p style={{ marginTop: -10 }}>Payment Id</p>}
                            <p style={{ marginTop: -10 }}>Mobile No</p>
                            <p style={{ marginTop: -10 }}>Address</p>

                          </div>
                          <div>
                          {record.shippingAddress.gstNumber && (<p>:</p>)}
                            <p>:</p>
                            <p style={{ marginTop: -10 }}>:</p>
                            {record.paymentType == "COD" && <p style={{ marginTop: -10 }}>:</p>}
                            <p style={{ marginTop: -10 }}>:</p>
                            <p style={{ marginTop: -10 }}>:</p>
                          </div>
                          <div className="address-value">
                          {record.shippingAddress.gstNumber && (<p>{record.shippingAddress.gstNumber}</p>)}
                            <p>{record.shippingAddress.addressType}</p>
                            <p style={{ marginTop: -10 }}>{record.paymentType}</p>
                            {record.paymentType === "COD" && (
                              <p style={{ marginTop: -10 }}>
                                {record.paymentDetails.paymentId ? record.paymentDetails.paymentId : "NA"}
                              </p>
                            )}
                            <p style={{ marginTop: -10 }}>+91 {record.shippingAddress.mobile}</p>
                            <p style={{ marginTop: -10 }}>{record.shippingAddress.streetAddress}</p>
                            {record.shippingAddress.address && (
                              <p style={{ marginTop: -10 }}>{record.shippingAddress.address}</p>
                            )}
                            <p style={{ marginTop: -10 }}>{record.shippingAddress.city}, {record.shippingAddress.state}, {record.shippingAddress.zipCode} {record.shippingAddress.country}</p>
                          </div>

                        </div>
                        {/* <div className="col-lg-3"></div> */}
                        <div
                          className="col-lg-4 manageorders-media"
                          style={{ marginTop: 16, display: "flex", gap: "40px" }}
                        >
                          <div className="summary-div">
                            <p>Subtotal :</p>
                            <p>Discount :</p>
                            {record.couponApplied && record.couponType !== "FREE_SHIPPING" && <p style={{ color: "#54C104" }}>Coupon Savings:</p>}
                            <p>Shipping :</p>
                            <p>_____________</p>
                            <p>Total :</p>
                            {record.paymentType === "COD" && <p>Amount Received:</p>}
                            {record.paymentType === "COD" && <p>Balance Amount:</p>}
                          </div>
                          <div>
                            <p> ₹ {(record.totalPrice ?? 0).toFixed(2)}</p>
                            <p>
                              {" "}
                              -<span style={{ color: "#ce1b28" }}>₹ {(record.discount ?? 0).toFixed(2)}</span>
                            </p>
                            {record.couponApplied && record.couponType !== "FREE_SHIPPING" && <p style={{ color: "#54C104" }}>-₹ {(record.couponDiscountedPrice ?? 0).toFixed(2)}</p>}
                            <p style={{ textDecoration: record.couponApplied && record.couponType === "FREE_SHIPPING" ? 'line-through' : 'none' }}> {(record.deliveryFee ?? 0).toFixed(2)}</p>
                            <p style={{ marginLeft: "-35px" }}>____________</p>
                            <p>₹ {(record.totalPriceIncludeDeliveryFee ?? 0).toFixed(2)}</p>
                            {record.paymentType === "COD" && <p>-₹ {(record.paymentDetails.amountReceived ?? 0).toFixed(2)}</p>}
                            {record.paymentType === "COD" && <p>₹ {((record.totalPriceIncludeDeliveryFee - record.paymentDetails.amountReceived) ?? 0).toFixed(2)}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                  expandedRowKeys,
                  onExpand: (expanded, record) => {
                    handleToggleDetails(record);
                  },
                }}
              />
            </>
          )}

          <div className="pagination-info">
            <p>
              Showing{" "}
              <Select
                defaultValue={pagination.pageSize}
                onChange={(value) =>
                  handlePageSizeChange(pagination.current, value)
                }
                style={{ width: 80 }}
                suffixIcon={<IoIosArrowDown />}
              >
                <Select.Option value={5}>5</Select.Option>
                <Select.Option value={10}>10</Select.Option>
                <Select.Option value={20}>20</Select.Option>
              </Select>{" "}
              out of {filteredData?.length}
            </p>
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={filteredData?.length}
              showSizeChanger={false}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Product Images"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Carousel
          arrows
          prevArrow={<LeftOutlined className="custom-arrow custom-left-arrow" />}
          nextArrow={<RightOutlined className="custom-arrow custom-right-arrow" />}
        >
          {modalImageSrc?.length > 0 ? (
            modalImageSrc.map((src, index) => (
              <div key={index}>
                <img
                  src={src}
                  alt={`Product ${index + 1}`}
                  style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                  onClick={() => handlePreviewImage(src)} // Added click handler
                />
              </div>
            ))
          ) : (
            <p>No images available</p>
          )}
        </Carousel>

        {previewImage && (
          <Image
            wrapperStyle={{
              display: "none",
            }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage(""),
            }}
            src={previewImage}
          />
        )}
      </Modal>
    </div>
  );
};

export default TransactionList;
