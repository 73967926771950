import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Dashboardpage from "./Pages/Dashboard";
import CouponPage from "./Pages/Coupon";
import TransactionPage from "./Pages/Transaction";
import SettingsPage from "./Pages/Settings";
import UsersPage from "./Pages/Users";
import ManageOrderPage from "./Pages/ManageOrders";
import AddCoupon from "./components/Coupon/AddCoupon";
import AddProducts from "./Pages/AddProduct";
// import CustomerDetails from "./components/Users/CustomerDetails";
import CustomerDetailsPage from "./Pages/Users/CustomerDetailsPage";
import ProductsListPage from "./Pages/ProductsList";
// import ViewProducts from "./components/ProductList/ViewProduct";
import ViewProductPage from "./Pages/ProductsList/ViewProduct";
import ProductCategoriesPage from "./Pages/ProductCategories";
import AccountPage from "./Pages/Account";
import Brandspage from "./Pages/Brands";
import ManageAdmin from "./Pages/ManageAdmins";
import AdminRole from "./Pages/AdminRoles";
import FaqsPage from "./Pages/Faqs";
// import Logout from "./Pages/Logout";
import CustomerSupportpage from "./Pages/CustomerSupport";
import { LoginPage } from "./components/Authentication/LoginPage";
import ReviewPage from "./Pages/Review";
import ForgotPassword from "./components/Authentication/ForgotPassword";
import VerifyCode from "./components/Authentication/VerifyCode";
import SetPassword from "./components/Authentication/SetPassword";
import Protect from "./Protect";
import LogoutPage from "./Pages/Logout";
import NotAccessible from "./components/Authentication/PageNotFound";
import ContactPage from "./Pages/Contact";
import StoreInform from "./Pages/StoreInformation";
import StoreDetail from "./Pages/StoreInformation/storeDetails";
import FeedbackPage from "./Pages/Feedback";
import ViewAllProduct from "./Pages/ProductCategories/ViewAllProduct";


const Router = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-code" element={<VerifyCode />} />
        <Route path="/set-password" element={<SetPassword />} />

        <Route
          path="/dashboard"
          element={<Protect Component={Dashboardpage} />}
        />
        <Route
          path="/coupon"
          element={<Protect Component={CouponPage} />} />
        <Route
          path="/transaction"
          element={<Protect Component={TransactionPage} />}
        />
        <Route

          path="/Users"
          element={<Protect Component={UsersPage} />}
        />

        <Route
          path="/manage-orders"
          element={<Protect Component={ManageOrderPage} />}
        />
        <Route
          path="/add-coupon"
          element={<Protect Component={AddCoupon} />}
        />
        <Route
          path="/add-product"
          element={<Protect Component={AddProducts} />}
        />
        <Route
          path="/customer-details"
          element={<Protect Component={CustomerDetailsPage} />}
        />
        <Route
          path="/products-list"
          element={<Protect Component={ProductsListPage} />}
        />
        <Route
          path="/product-details/:productListId"
          element={<Protect Component={ViewProductPage} />} />
        <Route
          path="/settings"
          element={<Protect Component={SettingsPage} />}
        />
        <Route
          path="/brands"
          element={<Protect Component={Brandspage} />}
        />
        <Route
          path="/manage-admin"
          element={<Protect Component={ManageAdmin} />}
        />
        <Route
          path="/store-information"
          element={<Protect Component={StoreInform} />}
        />

        <Route
          path="/store-details/:storeInfoId"
          element={<Protect Component={StoreDetail} />}
        />

        <Route path="/chat-support"
          element={<Protect Component={CustomerSupportpage} />}
        />
        <Route
          path="/admin-roles"
          element={<Protect Component={AdminRole} />}
        />
        <Route
          path="/ProductCategories"
          element={<Protect Component={ProductCategoriesPage} />}
        />
        <Route
          path="/view-all-products"
          element={<Protect Component={ViewAllProduct} />}
        />
        <Route
          path="/faqs"
          element={<Protect Component={FaqsPage} />}
        />
        <Route
          path="/Logout"
          element={<Protect Component={LogoutPage} />}
        />
        <Route
          path="/contact-us"
          element={<Protect Component={ContactPage} />}
        />
        <Route
          path="/feedback"
          element={<Protect Component={FeedbackPage} />}
        />
        <Route path="/account" element={< Protect Component={AccountPage} />} />
        <Route path="/review" element={<Protect Component={ReviewPage} />} />
        <Route path="/not-accessible" element={<NotAccessible />} />

      </Routes>
    </BrowserRouter>
  );
};

export default Router;
