import React, { useState } from "react";
import { Modal, Button, Input, Radio, message } from "antd";
import ReactQuill from "react-quill";
import Instance from "../../AxiosConfig";
import { addfaq } from "../../states/reducers/FAQslice";
import { useDispatch } from "react-redux";
const AddFaqs = ({ isModalVisible, setIsModalVisible, addFaqToList }) => {
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        status: true, 
    });
    const dispatch=useDispatch();
    
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        title: "",
        description: "",
        status: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: "",
        });
    };

    const handleQuillChange = (content) => {
        setValue(content);
        setFormData({
            ...formData,
            description: content,
        });
        setErrors({
            ...errors,
            description: "",
        });
    };

    const stripHtml = (html) => {
        let div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };

    const handleStatusChange = (e) => {
        setFormData({
            ...formData,
            status: e.target.value === "true", 
        });
        setErrors({
            ...errors,
            status: "",
        });
    };

    const handleSubmit = async () => {
        const strippedDescription = stripHtml(formData.description);
        let valid = true;

        const newErrors = {
            title: "",
            description: "",
            status: "",
        };

        if (!formData.title) {
            newErrors.title = "Title is required.";
            valid = false;
        }

        if (!strippedDescription) {
            newErrors.description = "Description is required.";
            valid = false;
        } else if (strippedDescription.length > 1200) {
            newErrors.description = "Description must be less than or equal to 1200 characters.";
            valid = false;
        }
        

        if (formData.status === undefined) {
            newErrors.status = "Status is required.";
            valid = false;
        }

        setErrors(newErrors);

        if (!valid) {
            return;
        }

        setLoading(true);
        try {
            const response = await Instance.post("/api/v1/faqs/create", { ...formData, description: strippedDescription }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
                    "Content-Type": "application/json",
                },
            });
            console.log("FAQ created successfully:", response.data);
            setFormData({
                title: "",
                description: "",
                status: true, 
            });
            setValue('');
            dispatch(addfaq(response.data));
            setIsModalVisible(false);
            message.success("FAQ created successfully");
            addFaqToList(response.data);
        } catch (error) {
            console.error("Error creating FAQ:", error);
        } finally {
            setLoading(false);
        }
    };

    const modules = {
        toolbar: [
            [{ 'font': [] }],
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],
            ['link']
        ]
    };

    const formats = [
        'font',
        'bold', 'italic', 'underline',
        'align',
        'link'
    ];

    return (
        <>
            <Modal
                title="Add FAQ's"
                visible={isModalVisible}
                onOk={handleSubmit}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button className="discard_button" key="cancel" onClick={() => setIsModalVisible(false)}>
                        Discard
                    </Button>,
                    <Button
                        className="save_changes_button"
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={handleSubmit}
                    >
                        Save Changes
                    </Button>,
                ]}
            >
                <div className="add-user-section">
                    <div className="row">
                        <div className="col-lg-12 mb-3">
                            <p className="add_couponpage_hedings">Add Title</p>
                            <Input
                                type="text"
                                name="title"
                                placeholder="What are the key features of UltraTech Cement?"
                                className="add_title_question"
                                value={formData.title}
                                onChange={handleInputChange}
                            />
                            {errors.title && <div className="error-message">{errors.title}</div>}
                        </div>
                        <div className="col-lg-12 mb-3">
                            <p className="add_couponpage_hedings">Description</p>
                            <ReactQuill
                                className="mb-3"
                                value={value}
                                onChange={handleQuillChange}
                                placeholder="Your text goes here"
                                modules={modules}
                                formats={formats}
                            />
                            {errors.description && <div className="error-message">{errors.description}</div>}
                        </div>
                        <div className="col-lg-12 mb-3">
                            <p className="add_couponpage_hedings">Status</p>
                            <Radio.Group
                                className="add_coupon_radio"
                                name="status"
                                onChange={handleStatusChange}
                                value={formData.status.toString()} 
                            >
                                <Radio value="true">Enabled</Radio>
                                <Radio value="false">Disabled</Radio>
                            </Radio.Group>
                            {errors.status && <div className="error-message">{errors.status}</div>}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AddFaqs;

