import { createSlice } from '@reduxjs/toolkit';

export const FAQSlice = createSlice({
    name: 'faq',
    initialState: {
      FAQData: [],
    },
    reducers: {
      addfaqs(state, action) {
        state.FAQData = action.payload;
      },
      addfaq(state, action) {
        console.log('Adding FAQ:', action.payload);
        state.FAQData.push(action.payload);
      },
      removefaq: (state, action) => {
        console.log('Removing FAQ with productId:', action.payload);
        state.FAQData = state.FAQData.filter(
          faq => faq.faqId !== action.payload
        );
      },
      editfaq(state, action) {
        const { faqId, title, description, status } = action.payload;
        const index = state.FAQData.findIndex(faq => faq.faqId === faqId);
        if (index !== -1) {
            state.FAQData[index] = { faqId, title, description, status };
        }
    },
    },
  });
  
  export const { addfaq, removefaq, addfaqs, editfaq } = FAQSlice.actions;
  export default FAQSlice.reducer;
  