import React, { useState, useEffect } from "react";
import { Table, Pagination, Select, Modal } from "antd";
import { IoFilter } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Instance from "../../AxiosConfig";
// import AddProducts from "./AddProducts";
// import "./Product.css";
import jsPDF from "jspdf";
import InvoiceLogo1 from "../../Assets/images/white-bg-logo.png";
import icon1 from "../../Assets/images/user-icon-1.png";
import icon2 from "../../Assets/images/order-icon-1.png";
import icon3 from "../../Assets/images/sales-icon-1.png";
import icon4 from "../../Assets/images/rupees-icon-1.png";
import { RiDeleteBinLine } from "react-icons/ri";
import axios from "axios";
import { message } from "antd";

const { Option } = Select;

const bulkActions = [
  { value: "In stock", label: "In Stock" },
  { value: "Low stock", label: "Low Stocks" },
  { value: "Out of stock", label: "Out of Stocks" },
];

const StoreInformations = ({ searchText, selectedDate, record }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  // const [selectedBulkActions, setSelectedBulkActions] = useState([]);

  const navigate = useNavigate();
  const { confirm } = Modal;

  // const handleExportRows = () => {
  //   const doc = new jsPDF();

  //   const tableData = filteredData.map((item, index) => [
  //     index + 1,
  //     item.productName,
  //     `${item.buyingPrice}`,
  //     item.quantity,
  //     item.remaining,
  //     item.receivedDate,
  //     item.availability,
  //   ]);

  //   const tableHeaders = [
  //     "SL.No",
  //     "PRODUCTS",
  //     "BUYING PRICE",
  //     "QUANTITY",
  //     "REMAINING",
  //     "RECEIVED DATE",
  //     "AVAILABILITY",
  //   ];

  //   doc.setFillColor("#fff");
  //   doc.rect(0, 0, doc.internal.pageSize.width, 40, "F");

  //   const logoImage = new Image();
  //   logoImage.src = InvoiceLogo1;

  //   logoImage.onload = function () {
  //     doc.addImage(logoImage, "PNG", 15, 10, 50, 20);

  //     doc.setFontSize(19);
  //     doc.setTextColor(80);

  //     const labely = 12;
  //     const baseY = 22;
  //     // const lineHeight = 7;

  //     const labelX = 90;
  //     const valueX = 165;
  //     const currentDate = new Date();
  //     const formattedDateTime = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;

  //     doc.text(`PRODUCT LIST`, labelX, baseY);
  //     doc.setFontSize(11);
  //     doc.text(formattedDateTime, valueX, labely);

  //     const pageWidth = doc.internal.pageSize.width;
  //     const pageHeight = doc.internal.pageSize.height;
  //     doc.rect(5, 5, pageWidth - 10, pageHeight - 10);

  //     doc.autoTable({
  //       head: [tableHeaders],
  //       body: tableData,
  //       startY: 40,
  //       headStyles: {
  //         fillColor: [27, 58, 87],
  //         textColor: [255, 255, 255],
  //       },
  //       styles: {
  //         cellPadding: 4,
  //         minCellHeight: 8,
  //         lineWidth: 0.2,
  //         lineColor: [200, 200, 200],
  //       },
  //       columnStyles: {
  //         0: { cellWidth: 15 }, // Adjust width for Serial Number column
  //       },
  //     });

  //     const totalPages = doc.getNumberOfPages();
  //     for (let i = 1; i <= totalPages; i++) {
  //       doc.setPage(i);
  //       doc.setFontSize(10);
  //       doc.text(`Page ${i} of ${totalPages}`, pageWidth - 20, pageHeight - 1, {
  //         align: "right",
  //       });
  //     }

  //     const pdfBase64 = doc.output("datauristring");
  //     const newWindow = window.open();
  //     newWindow.document.write(
  //       `<iframe width="100%" height="100%" src="${pdfBase64}"></iframe>`
  //     );
  //   };
  // };

  const handleOpenAddPostModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    fetchStoreInfo();
  }, []);

  // useEffect(() => {
  //   setPagination((prev) => ({ ...prev, current: 1 }));
  // }, [searchText, selectedDate, selectedBulkActions]);

  useEffect(() => {
    handleFilterAndPagination();
  }, [
    pagination.current,
    pagination.pageSize,
    // searchText,
    // selectedDate,
    // selectedBulkActions,
    data,
  ]);

  const fetchStoreInfo = async () => {
    setLoading(true);
    try {
      const url = "/api/v1/store-info/all";
      const response = await Instance.get(url);
      console.log("API Response:", response.data);

      if (response.status === 200) {
        const stores = response.data;
        setData(stores);

        console.log("Fetched Stores:", stores);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error.response);
    }
    setLoading(false);
  };

  const handleFilterAndPagination = () => {
    let filtered = data;
    // console.log("Selected Bulk Actions:", selectedBulkActions);

    // if (searchText) {
    //   filtered = filtered.filter((product) =>
    //     product.productName.toLowerCase().includes(searchText.toLowerCase())
    //   );
    // }

    // if (selectedDate) {
    //   filtered = filtered.filter(
    //     (product) =>
    //       new Date(product.createdDate).toLocaleDateString() ===
    //       new Date(selectedDate).toLocaleDateString()
    //   );
    // }

    // if (selectedBulkActions.length > 0) {
    //   const selectedStatuses = selectedBulkActions;
    //   filtered = filtered.filter((product) =>
    //     selectedStatuses.includes(product.availability)
    //   );
    // }

    setFilteredData(filtered);

    setPagination((prev) => ({
      ...prev,
      total: filtered.length,
    }));
  };

  const handleRowClick = (storeInfoId) => {
    navigate(`/store-details/${storeInfoId}`);
  };

  // const handleBulkActionsChange = (value) => {
  //   setSelectedBulkActions(value);
  // };
  const handleDeleteClick = (e, storeInfoId) => {
    e.preventDefault(); 
    e.stopPropagation();
    showDeleteConfirm(storeInfoId);  
  };

  const showDeleteConfirm = (storeInfoId) => {
    confirm({
      title: 'Are you sure you want to delete this store?',
      content: 'Once deleted, the store information cannot be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(storeInfoId);  
      },
      onCancel() {
        console.log('Cancel'); 
      },
    });
  };

  const handleDelete = async (storeInfoId) => {
    try {
      const response = await Instance.delete(`api/v1/store-info/delete/${storeInfoId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
        }
    });
      
      if (response.status === 200|| response.status===201) {
        message.success("Store information deleted successfully!");
        fetchStoreInfo();
        } else {
        message.error("Failed to delete the store information.");
      }
    } catch (error) {
      message.error("An error occurred while deleting the store.");
      console.error("Delete error:", error);
    }
  };

  const columns = [
    {
      title: "StoreId",
      dataIndex: "storeInfoId",
    },
    {
      title: "Store Manager",
      dataIndex: "storeManager",
      
    },
    {
      title: "Store Name",
      dataIndex: "storeName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Ph.No",
      dataIndex: "phoneNumber",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="delete_edit_icons">
          
          <RiDeleteBinLine
            className="delete_icon"
            onClick={(e) => handleDeleteClick(e, record.storeInfoId)}  // Pass the event and storeInfoId
            />{" "}
        </div>
      ),
    },

  ];

  const handleTableChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
      pageSize,
    }));
  };

  const handlePageSizeChange = (current, size) => {
    setPagination((prev) => ({
      ...prev,
      current,
      pageSize: size,
    }));
  };

  const paginatedData = filteredData.slice(
    (pagination.current - 1) * pagination.pageSize,
    pagination.current * pagination.pageSize
  );

  return (
    <div className="container">
      <div>
        <div className="product-column d-flex justify-content-between">
          <h5>Store Information</h5>
        </div>
      </div>
      <div className="table-list">
        <Table
          className="table-data"
          columns={columns}
          dataSource={paginatedData}
          pagination={false}
          loading={loading}
          onRow={(record) => ({
            onClick: () => handleRowClick(record.storeInfoId),
          })}
        />
        <div className="pagination-info">
          <p>
            Showing{" "}
            <Select
              defaultValue={pagination.pageSize}
              onChange={(value) =>
                handlePageSizeChange(pagination.current, value)
              }
              style={{ width: 80 }}
              suffixIcon={<IoIosArrowDown />}
            >
              <Option value={5}>5</Option>
              <Option value={10}>10</Option>
              <Option value={20}>20</Option>
            </Select>{" "}
            out of {pagination.total}
          </p>
          <Pagination
            current={pagination.current}
            total={pagination.total}
            pageSize={pagination.pageSize}
            showSizeChanger={false}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default StoreInformations;
