import React, { useState } from "react";
import "./Coupon.css"
import AddCoupon from "./AddCoupon"; // Import AddCoupon component
import { useNavigate } from "react-router-dom";
import CouponList from "./CouponList";
import { FaPlus } from "react-icons/fa6";

const Coupon = () => {
  const [postData, setPostData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const navigate = useNavigate();

  // const handleOpenAddPostModal = () => {
  //   setIsModalVisible(true); // Open modal
  // };

  return (
    <div className="container">
      <div className="main-title-all">
        <div style={{display:"flex", justifyContent:"start"}}>
          <span>Coupon Code</span>  
        </div>
        {/* <div style={{display:"flex", justifyContent:"end"}}>
          <button  onClick={handleOpenAddPostModal}><FaPlus/> &nbsp; New Coupon</button>
        </div> */}
      </div>
      

      {/* Pass modal visibility state and handler to AddCoupon */}
      {/* <AddCoupon isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} /> */}
      
      <CouponList data={postData} />
    </div>
  );
};

export default Coupon;