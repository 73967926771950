import React, { useState, useEffect } from "react";
import profile from "../../Assets/images/singleuser.png";
import ReviewReply from "./ReviewReply";
import Instance from "../../AxiosConfig";
import { message, Rate, Spin } from "antd";
import { HiOutlineInbox } from "react-icons/hi2";

export const ReviewList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRatingReviewId, setCurrentRatingReviewId] = useState(null);
  const [currentRating, setCurrentRating] = useState(0);
  const [reviewData, setReviewData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOpenAddPostModal = (ratingReviewId, rating) => {
    console.log("current rating:", rating);
    setCurrentRatingReviewId(ratingReviewId);
    setCurrentRating(rating);
    setIsModalVisible(true);
  };

  const fetchReviewData = async () => {
    setLoading(true)
    try {
      const url = `/api/v1/replies/rating-review/all`;
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("review data fetched successfully:", response);
        const formattedReviews = response.data.map((review) => {
          const date = new Date(review.createdDate);
          const formattedDate = new Intl.DateTimeFormat("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          }).format(date);
          return {
            ...review,
            formattedDate: `Posted on ${formattedDate}`,
          };
        });
        setReviewData(formattedReviews);
      }
    } catch (error) {
      console.log("error:", error)
      message.error(error?.response?.data?.message || "An error occurred");
    }finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReviewData();
  }, []);

  return (
    <div className="container">
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {reviewData.length === 0 ? (
            <div className="no-inquiries">
              <HiOutlineInbox style={{ color: "#ffded1", fontSize: "70px" }} />

              <p>No New Reviews Available</p>
            </div>
          ) : (
            reviewData.map((review) => (
              <div key={review.ratingReviewId} className="review-card-div mb-4">
                <div className="d-flex justify-content-between reviews-how-to-apply-div">
                  <div className="d-flex gap-4">
                    <h6 className="reviews-how-to-apply">{review.title}</h6>
                    <Rate allowHalf defaultValue={review.rating} />
                  </div>
                  <p className="reviews-posted-at">{review.formattedDate}</p>
                </div>
                <p>{review.review}</p>
                <div className="straight-line my-2"></div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-2 align-items-center">
                    <div className="profile-email-icon">
                      <img src={review.userImage || profile} alt="profile" />
                    </div>
                    <div>
                      <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
                        {review.userName}
                      </p>
                    </div>
                  </div>
                  <button
                    className="review-reply-button"
                    onClick={() => handleOpenAddPostModal(review.ratingReviewId, review.rating)}
                  >
                    Reply
                  </button>
                </div>
              </div>
            ))
          )}
          {currentRatingReviewId && (
            <ReviewReply
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              ratingReviewId={currentRatingReviewId}
              rating={currentRating}
            />
          )}
        </>)}
    </div>
  );
};
