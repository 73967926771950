import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../Layout/Header/header';
import Sidebar from '../../Layout/Header/Sidebar';
function NotAccessible() {
    
  return (
    <div>
    <Header/>
    <Sidebar/>
    <div className="main-wrapper">
    <div className="not-accessible-wrapper">
          <div className="not-accessible-content">
            <h2>Page Not Accessible</h2>
          </div>
        </div>
  </div>
</div>
  );
}

export default NotAccessible;
