import React from "react";
import icon1 from "../../Assets/images/user-icon-1.png";
import icon2 from "../../Assets/images/order-icon-1.png";
import icon3 from "../../Assets/images/sales-icon-1.png";
import icon4 from "../../Assets/images/rupees-icon-1.png";
import "./Account.css";
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import { AccountRecentTransactions } from "./AccountRecentTransactions";

export const AccountDashboard = () => {
  return (
    <>
      <div className="account-row1 mt-4">
        <div className="account-card">
          <div className="d-flex justify-content-between ">
            <div style={{textAlign:"left"}}>
              <p>Total User</p>
              <h4>40,689</h4>
            </div>
            <img src={icon1} className="icon-1-img" alt="" />
          </div>
          <p style={{ color: "#606060", fontSize: "16px" }}>
            <FaArrowTrendUp style={{ color: "#00b69b" }} />{" "}
            <span style={{ color: "#00b69b", fontSize: "16px" }}>8.5%</span>
            &nbsp; Up from Yesterday
          </p>
        </div>
        <div className="account-card">
          <div className="d-flex justify-content-between">
            <div style={{textAlign:"left"}}>
              <p>Total Order</p>
              <h4>10293</h4>
            </div>
            <img src={icon2} className="icon-1-img" alt="" />
          </div>
          <p style={{ color: "#606060", fontSize: "16px" }}>
            <FaArrowTrendUp style={{ color: "#00b69b" }} />{" "}
            <span style={{ color: "#00b69b", fontSize: "16px" }}>1.3%</span>
            &nbsp; Up from Yesterday
          </p>
        </div>
        <div className="account-card">
          <div className="d-flex justify-content-between">
            <div style={{textAlign:"left"}}>
              <p>Total Sales</p>
              <h4>4500</h4>
            </div>
            <img src={icon3} className="icon-1-img" alt="" />
          </div>
          <p style={{ color: "#606060", fontSize: "16px" }}>
            <FaArrowTrendDown style={{ color: "#F93C65" }} />{" "}
            <span style={{ color: "#F93C65", fontSize: "16px" }}>4.3%</span>
            &nbsp; Down from Yesterday
          </p>
        </div>
        <div className="account-card">
          <div className="d-flex justify-content-between">
            <div style={{textAlign:"left"}}>
              <p>Total Transaction</p>
              <h4>2040</h4>
            </div>
            <img src={icon4} className="icon-1-img" alt="" />
          </div>
          <p style={{ color: "#606060", fontSize: "16px" }}>
            <FaArrowTrendUp style={{ color: "#00b69b" }} />{" "}
            <span style={{ color: "#00b69b", fontSize: "16px" }}>1.8%</span>
            &nbsp; Up from Yesterday
          </p>
        </div>
      </div>
      <AccountRecentTransactions/>
    </>
  );
};
