import React from 'react'
import { AccountDashboard } from './AccountDashboard'

export const Account = () => {
  return (
    <>
      <div className="main-title-all">
        <div style={{display:"flex", justifyContent:"start"}}>
          <span>Account</span>  
        </div>
      </div>
      <AccountDashboard/>
    </>
  )
}
