import React, { useState, useEffect } from "react";
import { Table, Input, Space, Select, DatePicker, Pagination, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import profile from "../../Assets/images/singleuser.png";
import "./Users.css";
import { TbLockOpen2 } from "react-icons/tb";
import { CiLock } from "react-icons/ci";
import Instance from "../../AxiosConfig";
import { IoIosArrowDown } from "react-icons/io";
import moment from "moment";

const UsersList = () => {
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [paginatedData, setPaginatedData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchUsersData();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [data, searchText, selectedDate]);

  useEffect(() => {
    const startIndex = (pagination.current - 1) * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    setPaginatedData(filteredData.slice(startIndex, endIndex));
  }, [filteredData, pagination.current, pagination.pageSize]);

  const fetchUsersData = async () => {
    setLoading(true);
    try {
      const response = await Instance.get("/api/v1/user/all");
      console.log("UserData:", response.data)
      if (response.status === 200) {
        const users = response.data;
        const filteredUsers = users.filter(user => user.roles.length > 0 && user.roles[0].roleName == 'ROLE_NORMAL');
        console.log("only users:", filteredUsers)
        const usersWithLockStatus = filteredUsers.map(user => ({
          ...user,
          locked: false,
      }));
        setData(usersWithLockStatus);
        setPagination((prev) => ({
          ...prev,

          total: usersWithLockStatus.length,
        }));

      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
    setLoading(false);
  };

  const applyFilters = () => {
    let result = [...data];
    let isSearchOrDateApplied = false;

    if (searchText) {
      result = result.filter(user =>
        user.fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchText.toLowerCase())
      );
      isSearchOrDateApplied = true;
    }
    if (selectedDate) {
      result = result.filter(user =>
        moment(user.createdDate).isSame(selectedDate, 'day')
      );
      isSearchOrDateApplied = true;
    }
    setFilteredData(result);
    setPagination(prev => ({
      ...prev,
      total: result.length,
      current: isSearchOrDateApplied ? 1 : prev.current, // Reset to 1 if searchText or selectedDate is applied
    }));
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "fullName",
      render: (text, record) => (
        <div className="d-flex gap-4">
          <div className="profile-email-icon">
            <img style={{borderRadius:"50%"}}
              src={record.imageData ? `data:image/jpeg;base64,${record.imageData}` : profile}
              alt="Profile"
            />
          </div>
          <div>
            <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
              {record.fullName}
            </p>
            <p style={{ marginBottom: "0px" }}>{record.email}</p>
          </div>
        </div>
      ),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "mobile",
    },
    {
      title: "CREATED",
      dataIndex: "createdDate",
      sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
      render: (text) =>
        new Date(text).toLocaleDateString("en-us", {
          day: "numeric",
          month: "short",
          year: "numeric",
        }),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (_, record) => (
        <Space onClick={(e) => {
          e.stopPropagation();
          toggleLockStatus(record.userId);
        }}>
          {record.locked ? (
            <CiLock style={{ color: "#ce1b28", fontSize: "20px" }} />
          ) : (
            <TbLockOpen2 style={{ color: "#28c76f", fontSize: "20px" }} />
          )}
        </Space>
      ),
    },
  ];

  const handleTableChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
      pageSize,
    }));
  };

  const toggleLockStatus = async (userId) => {
    try {
      const response = await Instance.post(
        `/api/v1/user/lock/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
          },
        }
      );
      if (response.status === 200) {
        // Update the user lock status without resetting the pagination
        const updatedData = data.map((user) =>
          user.userId === userId ? { ...user, locked: !user.locked } : user
        );
  
        setData(updatedData);
        
      }
    } catch (error) {
      console.error("Failed to lock/unlock user:", error);
    }
  };

  const handleRowClick = async (record) => {
    const userId = record.userId;
    try {
      const url = `/api/v1/admin/orders/user/${userId}`;
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("User orders..:", response.data);
        const { users, totalElements } = response.data;

        navigate(`/customer-details`, { state: { userData: response.data } });
      }
    } catch (error) {
      console.error("Failed to fetch user orders:", error);
    }
  };

  const handlePageSizeChange = (current, size) => {
    setPagination((prev) => ({
      ...prev,
      current,
      pageSize: size,
    }));
  };

  const handleDateChange = (date, dateString) => {
    setSelectedDate(date ? moment(dateString, "YYYY-MM-DD") : null);
  };

  return (
    <div className="user-list-section container">
      <div className="main-title-all">
        <div style={{ display: "flex", justifyContent: "start", marginBottom: "30px" }}>
          <span> Users</span>
        </div>
      </div>
      <div className="filter-row">
        <div className="select-options media-userlist">
          <Input
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="search-input-table me-2"
            prefix={<SearchOutlined />}
          />
        </div>
        <div className="select-options media-userlist">
          <DatePicker
            placeholder="Filter by Date"
            format="YYYY-MM-DD"
            value={selectedDate ? moment(selectedDate) : null}
            onChange={handleDateChange}
          />
        </div>
      </div>
      <div className="table-list">
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Table
              className="table-data"
              columns={columns}
              dataSource={paginatedData}
              pagination={false}
              loading={loading}
              onChange={handleTableChange}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
              })}
            />

          </>
        )}

        <div className="pagination-info">
          <p>
            Showing{" "}
            <Select
              defaultValue={pagination.pageSize}
              onChange={(value) =>
                handlePageSizeChange(pagination.current, value)
              }
              style={{ width: 80 }}
              suffixIcon={<IoIosArrowDown />}
            >
              <Select.Option value={5}>5</Select.Option>
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={20}>20</Select.Option>
            </Select>{" "}
            out of {pagination.total}
          </p>
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={(page) =>
              setPagination((prev) => ({ ...prev, current: page }))
            }
            showSizeChanger={false}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersList;
