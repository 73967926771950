import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Contactlist } from "./ContactList";
const Contacts = () => {
  const [postData, setPostData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false); 
  const navigate = useNavigate();

  const handleOpenAddPostModal = () => {
    setIsModalVisible(true); 
  };

  return (
    <div className="container">
      <div className="main-title-all">
        <div style={{display:"flex", justifyContent:"start"}}>
          <span>Inquiries</span>  
        </div>
        
      </div>
      <Contactlist/>
    </div>
  );
};

export default Contacts;