import React, { useState } from 'react';
import { Modal, Button, message } from "antd";
import { FrownOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import Instance from '../../AxiosConfig';
import "../../components/Authentication/Auth.css";

const Logout = () => {
    const [modalVisible, setModalVisible] = useState(true); // Initially, show the modal
    const navigate = useNavigate();

    const handleSuccessModalClose = () => {
        setModalVisible(false);
        navigate("/dashboard"); // Navigate to the dashboard
    };

    const handleSignOut = async () => {
        try {
            const response = await Instance.post("/api/v1/auth/logout", null, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
                },
            });

            if (response.status === 200) {
                message.success("You have successfully logged out.");
                localStorage.removeItem("adminAuthToken");
                localStorage.removeItem('userData');

                navigate("/"); 
                setModalVisible(false);
            }
        } catch (error) {
            message.error("Error during sign out.");
        }
    };

    return (
        <>
            <div style={{ height: "400px" }}></div>
            <Modal
                visible={modalVisible}
                footer={null}
                onCancel={handleSuccessModalClose}
            >
                <div className="d-flex justify-content-center flex-column Logout-button">
                    <FrownOutlined
                        style={{ color: "#ce1b28", fontSize: "60px", padding: "15px" }}
                        className="d-flex justify-content-center"
                    />
                    <h4 className="text-center">Sure you want to Logout?</h4>
                    <p
                        style={{ color: "#54595e", textAlign: "center", fontSize: "14px", padding: "15px" }}
                    >
                        Are you sure you want to sign out?
                    </p>
                    <div className="d-flex gap-4 button-container justify-content-center">
                        <Button
                            type="primary"
                            className="admin-dashboard-logout-button"
                            onClick={handleSuccessModalClose}
                        >
                            No, Cancel
                        </Button>
                        <Button
                            type="primary"
                            className="admin-dashboard-logout-button"
                            id='login-btn'
                            onClick={handleSignOut}
                        >
                            Yes, Continue
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Logout;
