import React, { useState } from 'react'
import Sidebar from '../../Layout/Header/Sidebar';
import Header from '../../Layout/Header/header';
import Faqs from '../../components/Faqs/Faqs';
import { Spin } from 'antd';

const FaqsPage = () => {
  const [loading, setLoading] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };
  return (
    <>
    <Header/>
    <Sidebar isCollapsed={isSidebarCollapsed} toggleCollapse={toggleCollapse} />
    <div className={`main-wrapper ${isSidebarCollapsed ? 'collapsed' : ''}`}>
        {loading ? (
          <div className="pre-loader">
            <Spin size="large" className="custom-loader" />
          </div>
        ) : (
            
            <Faqs/>         
        )}
      </div>
    </>
  )
}
export default FaqsPage;