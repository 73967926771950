import React from 'react'
import TransactionList from './TransactionList'
import './Transaction.css'
const Transaction = () => {
  return (
    <div>
      <TransactionList/>   
    </div>
  )
}

export default Transaction

