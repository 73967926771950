import React, { useState } from "react";
import ProductCategoriesList from "./ProductCategoriesList";
import { FaPlus } from "react-icons/fa6";
import AddCategories from "./AddCategories";

const ProductCategories = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleOpenAddPostModal = () => {
    setIsModalVisible(true); // Open modal
  };
  return (
    <>
      <div className="main-title-all container">
        <div className="d-flex product-add-categories-div justify-content-between">
          <span>Product Categories</span>
          <button
            className="add-categories-button"
            onClick={handleOpenAddPostModal}
          >
            <FaPlus /> &nbsp; Add Categories
          </button>
        </div>
      </div>
      
      <ProductCategoriesList />
      <AddCategories
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
};

export default ProductCategories;
