import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import CustomerConvo from './CustomerConvo'
import NewMessages from './NewMessages';

export const Customer = () => {
    const [postData, setPostData] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedConvo, setSelectedConvo] = useState(null);
    const navigate = useNavigate();

    const handleOpenAddPostModal = () => {
        setIsModalVisible(true); // Open modal
    };
    return (
        <div>
            <div className=" customer-support-header">
                <h6>Conversation with Customers</h6>
                <button onClick={handleOpenAddPostModal} style={{ borderRadius: "10px", backgroundColor: "#1b3a57",color:"#fff",padding:"10px 20px" }}>New Message</button>
            </div>
            <NewMessages isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} setSelectedConvo={setSelectedConvo}  />
            <CustomerConvo data={postData} selectedConvo={selectedConvo} 
                setSelectedConvo={setSelectedConvo}/>
        </div>
    )
}
