import React, { useState, useEffect } from "react";
import { Table, Input, Tag, Pagination, Select, DatePicker, Modal, message, Popconfirm } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { RiDeleteBinLine } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import Instance from "../../AxiosConfig";
import { IoIosArrowDown } from "react-icons/io";
import EditCoupon from "./EditCoupon";
import moment from 'moment';

const { Option } = Select;
const { confirm } = Modal;


const CouponList = () => {
  const [searchText, setSearchText] = useState("");
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [createdDate, setCreatedDate] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchCouponData();
  }, []);

  useEffect(() => {
    applyFiltersAndPagination();
  }, [allData, createdDate, searchText, pagination.current, pagination.pageSize]);

  const fetchCouponData = async () => {
    setLoading(true);
    try {
      const url = "/api/v1/coupons/all?pageNumber=0&pageSize=10000&sortBy=couponId&sortDir=desc";
      const response = await Instance.get(url);
      if (response.status === 200) {
        const { content: coupons } = response.data;
        setAllData(coupons);
        setPagination(prev => ({
          ...prev,
          total: coupons.length, // Total data length after fetching
        }));
        console.log("Fetched Coupons:", coupons);
        
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
    setLoading(false);
  };

  const applyFiltersAndPagination = () => {
    let filtered = [...allData];
    
    // Filter by createdDate
    if (createdDate) {
      filtered = filtered.filter(coupon =>
        moment(coupon.createdDate).isSame(createdDate, 'day')
      );
    }
    
    // Filter by searchText
    if (searchText) {
      filtered = filtered.filter(coupon =>
        coupon.couponCode.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    // Update pagination state
    const totalFiltered = filtered.length; // Total items after filtering
    const start = (pagination.current - 1) * pagination.pageSize;
    const end = start + pagination.pageSize;
    const paginatedData = filtered.slice(start, end);

    setFilteredData(filtered);
    setDisplayedData(paginatedData);
    setPagination(prev => ({
      ...prev,
      total: totalFiltered, // Update total to reflect filtered data length
    }));
  };

  const handleDelete = async (couponCode) => {
    try {
      const url = `/api/v1/coupons/${couponCode}`;
      const response = await Instance.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        fetchCouponData();
        message.success(response.data.message);
      }
    } catch (error) {
      console.log({ error });
      message.error("Failed to delete coupon");
    }
  };

  // const showDeleteConfirm = (couponCode) => {
  //   confirm({
  //     title: 'Are you sure you want to delete this coupon?',
  //     content: 'Once deleted, the coupon cannot be recovered.',
  //     okText: 'Yes',
  //     okType: 'danger',
  //     cancelText: 'No',
  //     onOk() {
  //       handleDelete(couponCode);
  //     },
  //     onCancel() {
  //       console.log('Cancelled deletion');
  //     },
  //   });
  // };

  const handleOpenAddPostModal = async (couponCode) => {
    console.log("Product ID:", couponCode);
    try {
      const response = await Instance.get(`/api/v1/coupons/code/${couponCode}`);
      setSelectedProduct(response.data);
      console.log(response.data);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Failed to fetch product details:", error);
    }
  };

  const handleEditModalClose = () => {
    setIsModalVisible(false);
    setSelectedProduct(null);
    fetchCouponData();
  };

  const columns = [
    {
      title: "PRODUCT ID",
      dataIndex: "productId",
      render: (text) => (
        <div style={{ marginLeft: "20px" }}>
          {text}
        </div>
      ),
    },
    {
      title: "CODE",
      dataIndex: "couponCode",
      render: (text) => `${text}`,
    },
    {
      title: "TYPE",
      dataIndex: "couponType",
      render: (text) => {
        const formattedText = text
          .replace(/_/g, " ") 
        return formattedText;
      },
    },    
    {
      title: "DISCOUNT",
      dataIndex: "value",
      render: (value, record) =>
        (record.couponType === "FREE_SHIPPING") || (value === "") ||  (value === null) ? "FREE" : record.couponType === "percentage" ? `${value}%` : `${value}`,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (status) => {
        let color, style;
        switch (status) {
          case "enabled":
            color = "#28C76F";
            style = {
              fontSize: "14px",
              fontWeight: "semi-bold",
              border: "none",
              color: "#28C76F",
              padding: "1px 5px",
              backgroundColor: "#DDF6E8",
            };
            break;
          case "finished":
            color = "green";
            style = {
              fontSize: "14px",
              fontWeight: "semi-bold",
              border: "none",
              color: "#5983B3",
              padding: "1px 5px",
              backgroundColor: "#DDF6E8",
            };
            break;
          case "scheduled":
            color = "red";
            style = {
              fontSize: "14px",
              fontWeight: "semi-bold",
              border: "none",
              color: "#B9AD61",
              padding: "1px 5px",
              backgroundColor: "#FEFCE0",
            };
            break;
          default:
            color = "blue";
            style = {
              fontSize: "14px",
              fontWeight: "semi-bold",
              border: "none",
              color: "#28C76F",
              padding: "1px 5px",
              backgroundColor: "#DDF6E8",
            };
        }

        return (
          <Tag color={color} style={style}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "START DATE",
      dataIndex: "startDate",
      sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
      render: (text) =>
        new Date(text).toLocaleDateString("en-us", {
          day: "numeric",
          month: "short",
          year: "numeric",
        }),
    },
    {
      title: "END DATE",
      dataIndex: "endDate",
      sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
      render: (text) =>
        new Date(text).toLocaleDateString("en-us", {
          day: "numeric",
          month: "short",
          year: "numeric",
        }),
    },
    {
      title: "",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="delete_edit_icons">
          <Popconfirm
            title="Are you sure you want to delete this coupon?"
            onConfirm={() => handleDelete(record.couponCode)}
            onCancel={() => message.info('Coupon deletion aborted')}
            okText="Yes"
            cancelText="No"
          >
            <RiDeleteBinLine className="delete_icon" />
          </Popconfirm>
          <CiEdit
            className="edit_icon"
            onClick={() => handleOpenAddPostModal(record.couponCode)}
          />
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setPagination(prev => ({
      ...prev,
      current: pagination.current,
    }));
  };

  const handlePageSizeChange = (value) => {
    setPagination(prev => ({
      ...prev,
      pageSize: value,
      current: 1, // Reset to first page on page size change
    }));
  };

  const handleDateChange = (date) => {
    setCreatedDate(date ? date.format('YYYY-MM-DD') : null);
    setPagination(prev => ({
      ...prev,
      current: 1, // Reset to first page when date is changed
    }));
  };

  return (
    <div className="user-list-section">
      <div className="filter-row coupon-text">
        <div className="select-options media-coupon">
          <Input
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="search-input-table me-2"
            prefix={<SearchOutlined />}
          />
        </div>
        <div className="search-table-container">
          <DatePicker
            placeholder="Filter by Date"
            format="YYYY-MM-DD"
            value={createdDate ? moment(createdDate) : null}
            onChange={handleDateChange}
          />
        </div>
      </div>
      <div className="table-list">
        <Table
          className="table-data"
          columns={columns}
          dataSource={displayedData}
          pagination={false} // Disable built-in pagination
          loading={loading}
          onChange={handleTableChange}
        />
        <div className="pagination-info">
          <p>
            Showing{" "}
            <Select
              defaultValue={pagination.pageSize}
              onChange={handlePageSizeChange}
              style={{ width: 80 }}
              suffixIcon={<IoIosArrowDown />}
            >
              <Option value={5}>5</Option>
              <Option value={10}>10</Option>
              <Option value={20}>20</Option>
            </Select>{" "}
            out of {pagination.total}
          </p>
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            showSizeChanger={false}
            onChange={(page, pageSize) => {
              setPagination(prev => ({
                ...prev,
                current: page,
                pageSize: pageSize,
              }));
            }}
          />
        </div>
      </div>
      <EditCoupon 
        isModalVisible={isModalVisible} 
        setIsModalVisible={setIsModalVisible} 
        selectedProduct={selectedProduct} 
        fetchCouponData={fetchCouponData}
        onClose={handleEditModalClose} 
      />
    </div>
  );
};

export default CouponList;