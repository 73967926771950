import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import ManageAdminsList from "./ManageAdminsList"
import NewUser from "./NewUser";
import NewRole from "./NewRole";

const Manage = () => {
  const [postData, setPostData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false); // State for modal visibility
  const navigate = useNavigate();

  const handleOpenAddPostModal = () => {
    setIsModalVisible(true);
  };
  const handleOpenAddPostModal2 = () => {
    setIsModalVisible2(true);
  };

  return (
    <div className="container">
      <div className="main-title-all">
        <div style={{ display: "flex", justifyContent: "start" }}>
          <span>Manage Admins</span>
        </div>
        <div style={{ display: "flex", justifyContent: "end", gap: "25px" }}>
          <button onClick={handleOpenAddPostModal2}><FaPlus /> &nbsp; Add Role</button>
          <div>
            <button onClick={handleOpenAddPostModal}><FaPlus /> &nbsp; New User</button>
          </div>
        </div>
      </div>

      {/* Pass modal visibility state and handler to AddCoupon */}
      {/* <AddCoupon isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} /> */}
      <NewUser isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
      <NewRole isModalVisible2={isModalVisible2} setIsModalVisible2={setIsModalVisible2} />
      <ManageAdminsList data={postData} />
    </div>
  );
};

export default Manage;
