import React, { useState, useEffect } from "react";
import profile from "../../Assets/images/singleuser.png";
import Instance from "../../AxiosConfig";
import { message, Modal, Spin } from "antd";
import { HiOutlineInbox } from "react-icons/hi2";
import { RiDeleteBinLine } from "react-icons/ri";

const FeedbackList = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentContactUs, setCurrentContactUs] = useState(null);
    const [currentRating, setCurrentRating] = useState(0);
    const [contactData, setContactData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { confirm } = Modal;
    const handleOpenAddPostModal = (contactUsId) => {
        // console.log("current Cpntact Reply:", contactUsId);
        setCurrentContactUs(contactUsId);
        setIsModalVisible(true);
    };

    const fetchContactData = async () => {
        setLoading(true);
        try {
            const url = `/api/v1/feedBack`;
            const response = await Instance.get(url, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
                },
            });
            if (response.status === 200) {
                console.log("Feedback data fetched successfully:", response.data);
                const formattedContacts = response.data.map((contact) => {
                    const date = new Date(contact.createdDate);
                    const formattedDate = new Intl.DateTimeFormat("en-US", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                    }).format(date);
                    return {
                        ...contact,
                        formattedDate: `Posted on ${formattedDate}`,
                    };
                });
                setContactData(formattedContacts);
            }
        } catch (error) {
            console.log("error:", error);
            message.error(error?.response?.data?.message || "An error occurred");
        }finally{
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchContactData();
    }, []);


    const showDeleteConfirm = (feedbackId) => {
        confirm({
            title: 'Are you sure you want to delete this feedback?',
            content: 'Once deleted, the feedback cannot be recovered.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(feedbackId);
            },
            onCancel() {
                console.log('Cancelled deletion');
            },
        });
    };

    const handleDelete = async (feedbackId) => {
        try {
            const url = `/api/v1/feedBack/${feedbackId}`;
            const response = await Instance.delete(url, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                fetchContactData();
                message.success(response.data.message);
            }
        } catch (error) {
            console.log({ error });
            message.error("Failed to delete feedback");
        }
    };

    return (
        <div>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spin size="large" />
                </div>
            ) : (
                <>

                    {
                        contactData.length === 0 ? (
                            <div className="no-inquiries">
                                <HiOutlineInbox style={{ color: "#ffded1", fontSize: "70px" }} />

                                <p>No feedback available at the moment. Please check back later!</p>
                            </div>
                        ) : (
                            contactData.map((contact, index) => (
                                <div key={index} className="review-card-div mb-4">
                                    <div className="d-flex justify-content-between reviews-how-to-apply-div">
                                        <div className="d-flex gap-4">
                                            <div className="d-flex gap-2 align-items-center">
                                                <div className="profile-email-icon">
                                                    <img src={profile} alt="profile" />
                                                </div>
                                                <div>
                                                    <p style={{ fontWeight: "bold", marginBottom: "0px", marginTop: '5px', color: '#666' }}>
                                                        {contact.userName}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <p style={{ color: '#666', marginTop: "5px", fontWeight: 500 }} className="reviews-posted-at">{contact.formattedDate}</p>
                                    </div>
                                    {/* <p className="reviews-how-to-apply mt-2">{contact.subject}</p> */}
                                    <p style={{ fontWeight: 400 }} className="mt-3"><strong>Feedback Type:</strong> {contact.feedbackType}</p>
                                    <p style={{ fontWeight: 400 }} className="mt-3"><strong>Message:</strong>  &nbsp;{contact.message}</p>
                                    <div className="straight-line my-2"></div>
                                    <div className="">
                                        <button
                                            style={{ width: '120px', fontWeight: 500, fontSize: '17px', border: 'none', borderRadius: '5px', padding: '6px', color: 'red' }}
                                            onClick={() => showDeleteConfirm(contact.feedbackId)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            ))
                        )
                    }
                </>
            )}
        </div>
    );
};
export default FeedbackList;