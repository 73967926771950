import React from 'react';
import './NotificationModel.css';
import { MdPerson } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const NotificationModelMenu = ({ notifications, setIsModalVisible}) => {
    const navigate=useNavigate()
    const handleDivClick = (orderId) => {
        navigate('/manage-orders', { state: { orderId } });
        setIsModalVisible(false);
      };
    return (
        <div className='scrollable-container notification-scrollbar-container'>
            <div className='container'>
                <div className='row'>
                    {notifications.length === 0 ? (
                        <p>No notifications available.</p>
                    ) : (
                        notifications.map(notification => (
                            <div key={notification.orderId} className="col-12 notification-model-menu-divs">
                                <div className=''>
                                    <div className='notification-page-dot'></div>
                                </div>
                                <div onClick={() => handleDivClick(notification.orderId)} style={{ cursor: 'pointer' }}>
                                    <p>OrderId<span className='notification-model-menu-span'>&nbsp; #{notification.orderId}</span></p>
                                    <div className='notifiation-model-icon-div'>
                                        <MdPerson className='notification-model-human-icon' style={{ fontSize: '30px', color: '#ED785E' }} />
                                        <p>you got a new order from<span className='notification-model-menu-span'> &nbsp; {notification.username}</span> &nbsp; ordered on {notification.orderDate}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default NotificationModelMenu;
