import React, { useState } from "react";
import { Modal, Input } from "antd";
import customer1 from "../../Assets/images/customer-1.png";
import customer2 from "../../Assets/images/customer-2.png";
import customer3 from "../../Assets/images/customer-3.png";
import { IoSearchOutline } from "react-icons/io5";
import './Customer.css';

const conversations = [
    { id: 1, name: "Jane Doe", lastActivity: "12 Aug 2022 - 12:55 am", img: customer1, dotColor: "#5570f1" },
    { id: 2, name: "Janet Adebayo", lastActivity: "12 Aug 2022 - 12:55 am", img: customer2, dotColor: "#5570f1" },
    { id: 3, name: "Kunle Adekunle", lastActivity: "12 Aug 2022 - 12:55 am", img: customer3, dotColor: "#5570f1" },
    { id: 4, name: "Jane Doe", lastActivity: "12 Aug 2022 - 12:55 am", img: customer1, dotColor: "#5570f1" },
    { id: 5, name: "Janet Adebayo", lastActivity: "12 Aug 2022 - 12:55 am", img: customer2, dotColor: "#5570f1" },
    { id: 6, name: "Kunle Adekunle", lastActivity: "12 Aug 2022 - 12:55 am", img: customer3, dotColor: "#5570f1" },
    { id: 7, name: "Jane Doe", lastActivity: "12 Aug 2022 - 12:55 am", img: customer1, dotColor: "#5570f1" },
    { id: 8, name: "Janet Adebayo", lastActivity: "12 Aug 2022 - 12:55 am", img: customer2, dotColor: "#5570f1" },
    { id: 9, name: "Kunle Adekunle", lastActivity: "12 Aug 2022 - 12:55 am", img: customer3, dotColor: "#5570f1" },
    { id: 10, name: "Kunle Adekunle", lastActivity: "12 Aug 2022 - 12:55 am", img: customer1, dotColor: "#5570f1" },
];

const NewMessages = ({ isModalVisible, setIsModalVisible, setSelectedConvo }) => {

    const [selectedConvo, setSelectedConvoState] = useState(null);

    const handleConvoClick = (convo) => {
        setSelectedConvoState(convo.id);
        setSelectedConvo(convo);
        setIsModalVisible(false);
    };

    return (
        <Modal
            title="New Message"
            visible={isModalVisible}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
            className="message-chat"
        >
            <div>
                <span style={{ color: "#8b8d97" }}>Search a Customer to start a conversation</span>
                <Input
                    size="large"
                    placeholder="Search customer name"
                    prefix={<IoSearchOutline />}
                    style={{ width: "350px" }}
                    className="header-search-bar mt-4"
                />
                {conversations.map((convo) => (
                    <div
                        key={convo.id}
                        className={`convo-item ${selectedConvo === convo.id ? 'active' : ''}`}
                        onClick={() => handleConvoClick(convo)}
                    >
                        <div className="convo-img-wrapper">
                            <img src={convo.img} alt={convo.name} className="convo-img" />
                            <span className="convo-dot" style={{ backgroundColor: convo.dotColor }}></span>
                        </div>
                        <div className="convo-details">
                            <div className="convo-name">{convo.name}</div>
                            <div className="convo-last-activity" style={{color:"#8b8d97"}}>Last Activity</div>
                            <div className="convo-time">{convo.lastActivity}</div>
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export default NewMessages;
