import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import FaqsList from "./FaqsList";
import AddFaq from "./AddFaqs";
const Coupon = () => {
  const [postData, setPostData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false); 
  const navigate = useNavigate();
  const handleOpenAddPostModal = () => {
    setIsModalVisible(true); 
  };
  const addFaqToList = (newFaq) => {
    setPostData(prevFaqs => [newFaq, ...prevFaqs]);
  };
  return (
    <div className="container">
      <div className="main-title-all mb-3">
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <span>FAQ's</span>  
          <button onClick={handleOpenAddPostModal}><FaPlus/> &nbsp; Add FAQ's</button>
        </div>
      </div>
      <AddFaq isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} addFaqToList={addFaqToList} />
      <FaqsList data={postData} />
    </div>
  );
};
export default Coupon;