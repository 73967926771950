import React, { useEffect, useState } from 'react';
import SalesDetailsChart from './SalesDetailsChart';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import "./Dashboard.css";
import Instance from '../../AxiosConfig';

const SalesDetails = () => {
    const [data, setData] = useState({
        totalOrdersLast7Days: 0,
        totalProductsLast7Days: 0,
        totalSellingLast7Days: 0,
        orders: [],
        weeklyOrderCounts: {},
        weeklyProductCounts: {},
        weeklySellingCounts: {},
        monthlySellingCounts: {},
    });
    const [selectedMonth, setSelectedMonth] = useState(moment().format('MMMM').toUpperCase()); // Current month by default
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchOrdersData();
    }, []);

    const fetchOrdersData = async () => {
        setLoading(true);
        try {
            const url = "/api/v1/admin/orders/dashboard";
            const response = await Instance.get(url);
            if (response.status === 200 || response.status === 201) {
                const ordersData = response.data;
                setData(ordersData);
                console.log("Fetched data:", ordersData);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
        setLoading(false);
    };

    const onChange = (date, dateString) => {
        if (date) {
            const month = moment(dateString, 'YYYY-MM').format('MMMM').toUpperCase(); // Parse dateString instead of date
            console.log("Selected Month:", month);
            setSelectedMonth(month);
        } else {
            const currentMonth = moment().format('MMMM').toUpperCase(); // Reset to current month if no date is selected
            console.log("Reset to Current Month:", currentMonth);
            setSelectedMonth(currentMonth);
        }
    };

    const getMonthData = () => {
        console.log("Current Monthly Selling Counts:", data.monthlySellingCounts);
        const monthData = data.monthlySellingCounts[selectedMonth] || 0;
        console.log("Data for Selected Month:", selectedMonth, monthData);
        return monthData;
    };

    return (
        <div className="card sales-details-card mt-4" style={{ overflow: "hidden" }}>
            <div className="d-flex sales-details-graph-date justify-content-between pt-4 px-2">
                <h2>Sales Details</h2>
                <div className="d-flex justify-content-between gap-2">
                    <p className='sales-details-monthly-data'>
                        {`Sales of ${selectedMonth}: ${getMonthData()}`}
                    </p>
                    <Space direction="vertical">
                        <DatePicker onChange={onChange} picker="month" />
                    </Space>
                </div>
            </div>

            <SalesDetailsChart />
        </div>
    );
};

export default SalesDetails;
