import React, { useState, useRef, useEffect } from 'react';
import './Settings.css';
import { CgProfile } from 'react-icons/cg';
import { FaKey } from 'react-icons/fa';
import { MdStorefront } from 'react-icons/md';
import { DatePicker, message } from 'antd';
import Password from './Password';
import StoreInformation from './StoreInformation';
import ImgUrl from "../../Assets/images/singleuser.png";
import { PiNotePencilBold } from "react-icons/pi";
import Instance from '../../AxiosConfig';
import moment from 'moment';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useLocation } from 'react-router-dom';

const PersonalInfo = () => {
    const [fileList, setFileList] = useState([]);
    const [activeSection, setActiveSection] = useState('profile');
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const initialSection = query.get('section') || 'profile';


    const [formData, setFormData] = useState({
        profilePicture: ImgUrl,
        fullName: '',
        email: '',
        dateOfBirth: '',
        mobile: '',
        streetAddress: '',
    });

    const [originalData, setOriginalData] = useState({});


    const fetchUserData = async () => {
        setLoading(true);
        try {
            const response = await Instance.get("/api/v1/user/", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
                    "Content-Type": "multipart/form-data",
                }
            });
            if (response.status === 200) {
                const data = response.data;
                setUserData(data);
                const formattedData = {
                    profilePicture: data.imageData ? `data:image/jpeg;base64,${data.imageData}` : ImgUrl,
                    fullName: data.fullName || '',
                    email: data.email || '',
                    dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth) : null,
                    mobile: data.mobile !== 0 ? String(data.mobile) : '',
                    streetAddress: data.streetAddress || ''
                };

                setFormData(formattedData);
                setOriginalData(formattedData);
                console.log("User data fetched successfully:", response.data);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            message.error(error?.response?.data?.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    useEffect(() => {
        const section = query.get('section');
        if (section) {
            setActiveSection(section);
        }
    }, [location.search]);

    const hasChanges = () => {
        return JSON.stringify(formData) !== JSON.stringify(originalData);
    };


    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const fileInputRef = useRef(null);

    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await Instance.put("/api/v1/admin/upload-image", formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
                    "Content-Type": "multipart/form-data",
                }
            });
            if (response.status === 200) {
                message.success("Image uploaded successfully");
                setFormData((prevData) => ({
                    ...prevData,
                    profilePicture: URL.createObjectURL(file),
                }));

            }
        } catch (error) {
            console.error("Error uploading image:", error);
            message.error(error?.response?.data?.message || "An error occurred while uploading the image");
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData((prevData) => ({
                    ...prevData,
                    profilePicture: reader.result,
                }));
            };
            reader.readAsDataURL(file);
            uploadImage(file);
        }
    };

    const handleEditClick = () => {
        fileInputRef.current.click();
    };

    const getActiveClass = (section) => (activeSection === section ? 'active' : '');

    const enableEditMode = () => {
        setEditMode(true);
    };



    const updateUser = async (e) => {
        e.preventDefault();
        if (!hasChanges()) {
            message.info("No changes detected");
            return;
        }

        try {
            setLoading(true);
            const updatedData = {
                ...formData,
                dateOfBirth: formData.dateOfBirth ? formData.dateOfBirth.format('YYYY-MM-DD') : null
            };

            console.log("Sending updated data:", updatedData);
            const response = await Instance.put("/api/v1/user/update", updatedData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
                    "Content-Type": "application/json",
                }
            });

            if (response.status === 200) {
                message.success("User data updated successfully");
                console.log("After update:", response);
                // fetchUserData();
            }
        } catch (error) {
            console.error("Error updating user data:", error);
            message.error(error?.response?.data?.message || "An error occurred");
        } finally {
            setLoading(false);
            setEditMode(false);
        }
    };



    return (
        <div className="container-fluid d-flex settings-personal-info-div">
            <div className='row' style={{width:"100%"}}>
                <div className='col-lg-4'>
                    <div className="setting-sidebar card">
                        <div
                            onClick={() => setActiveSection('profile')}
                            className={`settings-items ${getActiveClass('profile')}`}
                        >
                            <CgProfile style={{ width: '20px', height: '20px' }} /> &nbsp; Profile Information
                        </div>
                        <div
                            onClick={() => setActiveSection('password')}
                            className={`settings-items ${getActiveClass('password')}`}
                        >
                            <FaKey style={{ width: '20px', height: '20px' }} /> &nbsp; Password
                        </div>
                        <div
                            onClick={() => setActiveSection('store')}
                            className={`settings-items ${getActiveClass('store')}`}
                        >
                            <MdStorefront style={{ width: '20px', height: '20px' }} /> &nbsp; Store Information
                        </div>
                    </div>
                </div>
                <div className='col-lg-8'>
                    <div className="content-profile">
                        {activeSection === 'profile' && (
                            <>
                                <h4 className="text-align-left d-flex pb-2">Personal Information</h4>
                                <div className="profile-icon-section pb-4">
                                    <img src={formData.profilePicture} alt="Profile" className="profile-image" />
                                    <button type="button" className="edit-icon-button" onClick={handleEditClick}>
                                        <PiNotePencilBold style={{ width: "24px", height: "24px" }} />
                                    </button>

                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                        accept="image/*"
                                    />
                                </div>
                                <form onSubmit={updateUser}>
                                    <div className="row pb-4 settings-personalinfo-row">
                                        <div className="col">
                                            <label className="text-align-left d-flex pb-2">Full name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Full Name"
                                                aria-label="User name"
                                                style={{ padding: "10px", borderRadius: "7px" }}
                                                value={formData.fullName}
                                                onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                                                readOnly={!editMode}
                                            />
                                        </div>
                                        <div className="col">
                                            <label className="text-align-left d-flex pb-2">E-mail</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Email"
                                                aria-label="email"
                                                style={{ padding: "10px", borderRadius: "7px" }}
                                                value={formData.email}
                                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                readOnly
                                                onClick={() => message.error('Cannot be edited')}
                                            />
                                        </div>
                                    </div>
                                    <div className="row pb-4 settings-personalinfo-row">
                                        <div className="col">
                                            <label className="text-align-left d-flex pb-2">Date of birth</label>
                                            <DatePicker
                                                className="form-control"
                                                onChange={(date) => setFormData({ ...formData, dateOfBirth: date })}
                                                value={formData.dateOfBirth}
                                                placeholder="Date of Birth"
                                                aria-label="Date of birth"
                                                disabled={!editMode}
                                                style={{ borderRadius: "7px" }}
                                            />
                                        </div>
                                        <div className="col">
                                            <label className="text-align-left d-flex pb-2">Mobile Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Mobile Number"
                                                aria-label="mobile number"
                                                style={{ padding: "10px", borderRadius: "7px" }}
                                                value={formData.mobile}
                                                onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
                                                readOnly={!editMode}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <label className="text-align-left d-flex pb-2">Address</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="streetAddress"
                                            aria-label="streetAddress"
                                            style={{ padding: "10px", borderRadius: "7px" }}
                                            value={formData.streetAddress}
                                            onChange={(e) => setFormData({ ...formData, streetAddress: e.target.value })}
                                            readOnly={!editMode}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end pt-4 gap-2 settings-personalinfo-buttons">

                                        <button
                                            type="button"
                                            style={{ backgroundColor: "#f6f6f6", border: "none", borderRadius: "15px", color: "#1b3a57", padding: "10px 30px" }}
                                            onClick={enableEditMode}

                                        >
                                            <MdOutlineModeEditOutline /> Edit
                                        </button>

                                        <button
                                            type="submit"
                                            style={{ backgroundColor: "#1b3a57", border: "none", borderRadius: "15px", color: "#fff", padding: "10px 15px" }}
                                        >
                                            Save Changes
                                        </button>

                                    </div>
                                </form>
                            </>
                        )}
                        {activeSection === 'password' && <Password />}
                        {activeSection === 'store' && <StoreInformation />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalInfo;
