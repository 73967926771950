import React, { useEffect, useState } from "react";
import { DatePicker, Space, Table } from "antd";
import Instance from "../../AxiosConfig";
import moment from "moment";
import "./Dashboard.css";

const DealsDetails = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        totalOrdersLast7Days: 0,
        totalProductsLast7Days: 0,
        totalSellingLast7Days: 0,
        orders: [], // Initialize orders as an empty array
    });
    const [selectedMonth, setSelectedMonth] = useState(null);

    useEffect(() => {
        fetchOrdersData();
    }, [selectedMonth]); // Re-fetch data when selectedMonth changes

    const fetchOrdersData = async () => {
        setLoading(true);
        try {
            const url = "/api/v1/admin/orders/dashboard";
            const response = await Instance.get(url);
            if (response.status === 200 || response.status === 201) {
                const ordersData = response.data;
                setData(ordersData); // Set the entire data object
                console.log("Fetched data:", ordersData);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
        setLoading(false);
    };

    const handleDateChange = (date) => {
        setSelectedMonth(date); // Update selected month state
        console.log("Selected Month:", date);
    };

    const filteredData = selectedMonth
        ? data.orders.filter(item => {
            const itemDate = moment(item.orderDate);
            const selectedYear = selectedMonth.year();
            const selectedMonthNumber = selectedMonth.month(); // Note: months are 0-based in moment.js

            const isSameYear = itemDate.year() === selectedYear;
            const isSameMonth = itemDate.month() === selectedMonthNumber;

            console.log(`Filtering: ${itemDate.format('YYYY-MM-DD')} - isSameYear: ${isSameYear} - isSameMonth: ${isSameMonth}`);
            return isSameYear && isSameMonth;
        })
        : data.orders;

    const columns = [
        {
            title: "ORDER ID",
            dataIndex: "orderId",
            key: "orderId",
            className: "custom-header-cell",
            width: 150,
            render: (text, record) => (
                <span>
                    {record.orderItems && record.orderItems.length > 0 && record.orderItems[0].imageData ? (
                        <img src={`data:image/png;base64,${record.orderItems[0].imageData}`} alt={`Order ID: ${text}`} style={{ width: '50px', marginRight: '10px' }} />
                    ) : null}
                    {text ? `#${text.toString().slice(-4)}` : ''}
                </span>
            ),
        },
        {
            title: <div className="custom-header">LOCATION</div>,
            dataIndex: "location",
            key: "location",
            className: "custom-header-cell",
            render: (text, record) => (
                <span>
                    {record.shippingAddress ? (
                        <>
                            {record.shippingAddress.city}
                            <br />
                            {record.shippingAddress.zipCode}
                        </>
                    ) : 'N/A'}
                </span>
            ),
        },
        {
            title: <div className="custom-header">DATE - TIME</div>,
            dataIndex: "orderDate",
            key: "orderDate",
            className: "custom-header-cell",
        },
        {
            title: <div className="custom-header">QUANTITY</div>,
            dataIndex: "totalItem",
            key: "totalItem",
            className: "custom-header-cell",
            render: (text) => (
                <div style={{ marginLeft: '20px' }}>{text}</div>  // Adjust the margin as needed
            ),
        },
        {
            title: <div className="custom-header">AMOUNT</div>,
            dataIndex: "totalPrice",
            key: "totalPrice",
            className: "custom-header-cell",
            render: (text, record) => (
                <span>
                    {record.couponApplied ? record.totalAfterDiscount : record.totalDiscountedPrice}
                </span>
            ),
        },
        {
            title: <div className="custom-header">PAYMENT TYPE</div>,
            dataIndex: "paymentType",
            key: "paymentType",
            className: "custom-header-cell",
        },
        {
            title: "ORDER STATUS",
            dataIndex: "orderStatus",
            className: "custom-header-cell",
            render: (status) => {
                let color = "";
                let backgroundColor = "";
                if (status === "DELIVERED") {
                    color = "#28C76F";
                    backgroundColor = "#ddf6e8";
                } else if (status === "IN_PROGRESS") {
                    color = "#F97316";
                    backgroundColor = "#fee7d7";
                } else if (status === "CANCELLED") {
                    color = "#FF0000";
                    backgroundColor = "#fde0e0";
                } else if (status === "PENDING") {
                    color = "#FFD591";
                    backgroundColor = "#fff7e6";
                } else if (status === "SHIPPED") {
                    color = "#b7eb8f";
                    backgroundColor = "#f6ffed";
                } else if (status === "ORDER_PLACED") {
                    color = "#00008B";
                    backgroundColor = "#ADD8E6";
                }
                return (
                    <div style={{ width: "110px", backgroundColor, padding: "4px", borderRadius: "5px" }}>
                        <span style={{ color, justifyContent: "center", display: "flex" }}>{status}</span>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="card mt-4" style={{ overflow: "hidden" }}>
            <div className="d-flex justify-content-between p-4">
                <h2>Order Details</h2>
                <Space direction="vertical">
                    <DatePicker onChange={handleDateChange} picker="month" />
                </Space>
            </div>
            <div className="user-list-section">
                <div className="table-list custom-scrollbar">
                    <Table
                        className="custom-sales-table custom-sales-table-dashboard"
                        columns={columns}
                        dataSource={filteredData}
                        rowKey={(record) => record.orderId}
                        pagination={false}
                        loading={loading}
                        style={{ padding: "0px 15px" }}
                    />
                </div>
            </div>
        </div>
    );
};

export default DealsDetails;
