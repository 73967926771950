import React from 'react'
import PersonalInfo from './PersonalInfo'


export const Settings = () => {
  return (
    <div className='container'>
      <div className="main-title-all d-flex justify-content-start">
        <span>Settings</span>
      </div>
      <PersonalInfo />
    </div>
  )
}
