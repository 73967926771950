import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FeedbackList from "./FeedbackList";
const Feedback = () => {

  return (
    <div className="container">
      <div className="main-title-all">
        <div style={{display:"flex", justifyContent:"start"}}>
          <span>Feedbacks</span>  
        </div>
        
      </div>
      <FeedbackList/>
    </div>
  );
};

export default Feedback;
