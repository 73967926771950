import React, { useState, useEffect } from 'react';
import customer1 from "../../Assets/images/customer-1.png";
import customer2 from "../../Assets/images/customer-2.png";
import customer3 from "../../Assets/images/customer-3.png";
import messageImg from "../../Assets/images/message-icon.png";
import { Input, Tooltip, Button } from 'antd';
import { IoSearchOutline, IoBagOutline } from 'react-icons/io5';
import { GoDotFill } from 'react-icons/go';
import imgProduct from "../../Assets/images/product.png";
import { FaCircleCheck, FaSquarePlus } from 'react-icons/fa6';
import { TbMessageDots } from 'react-icons/tb';
import { HiOutlineEmojiHappy } from 'react-icons/hi';
import { LiaTelegramPlane } from 'react-icons/lia';
import { HiOutlineChevronLeft } from "react-icons/hi";

import { IoCloseOutline } from 'react-icons/io5'; // Import the close icon

import './Customer.css';

const conversations = [
    { id: 1, name: "Jane Doe", message: "Hi, I want to make enquiries about you...", time: "12:55 am", img: customer1, dotColor: "#1b3a57" },
    { id: 2, name: "Janet Adebayo", message: "Hi, I want to make enquiries about you...", time: "12:55 am", img: customer2, dotColor: "#d3d3d3" },
    { id: 3, name: "Kunle Adekunle", message: "Hi, I want to make enquiries about you...", time: "12:55 am", img: customer3, dotColor: "#1b3a57" },
    { id: 4, name: "Jane Doe", message: "Hi, I want to make enquiries about you...", time: "12:55 am", img: customer1, dotColor: "#d3d3d3" },
    { id: 5, name: "Janet Adebayo", message: "Hi, I want to make enquiries about you...", time: "12:55 am", img: customer2, dotColor: "#1b3a57" },
    { id: 6, name: "Kunle Adekunle", message: "Hi, I want to make enquiries about you...", time: "12:55 am", img: customer3, dotColor: "#d3d3d3" },
    { id: 7, name: "Jane Doe", message: "Hi, I want to make enquiries about you...", time: "12:55 am", img: customer1, dotColor: "#1b3a57" },
    { id: 8, name: "Janet Adebayo", message: "Hi, I want to make enquiries about you...", time: "12:55 am", img: customer2, dotColor: "#d3d3d3" },
    { id: 9, name: "Kunle Adekunle", message: "Hi, I want to make enquiries about you...", time: "12:55 am", img: customer3, dotColor: "#1b3a57" },
    { id: 10, name: "Kunle Adekunle", message: "Macbook Pro", time: "12:55 am", img: customer1, dotColor: "#d3d3d3" },
];

const sampleMessages = [
    { from: "Jane Doe", text: "Hello, I want to make enquiries about your product", time: "12:57 am", product: { img: imgProduct, name: "Ramco", price: "₹ 599", stock: "12 In stock" } },
    { from: "You", text: "Hello Janet, thank you for reaching out", time: "12:57 am" },
    { from: "You", text: "What do you need to know?", time: "12:57 am" },
    { from: "Jane Doe", text: "I want to know if the price is negotiable, I need about 2 units", time: "12:55 am" },
    { from: "You", text: "Ok", time: "12:57 am" },
];

const DateSeparator = ({ date }) => (
    <div className="date-separator">
        <span className="head-chat-time">{date}</span>
    </div>
);

const messagesWithDate = [];
sampleMessages.forEach((msg, index) => {
    if (index === 0) {
        messagesWithDate.push({ type: 'date', date: '12 July 2024' });
    }
    messagesWithDate.push(msg);
    if (index === 2) {
        messagesWithDate.push({ type: 'date', date: 'Today' });
    }
});

const CustomerConvo = () => {
    const [selectedConvo, setSelectedConvo] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="customer-convo">
            {!selectedConvo || !isMobile ? (
                <div className="convo-list">
                    <div className="d-flex justify-content-between">
                        <h5>Contacts</h5>
                        <span style={{ color: "#A6A8B1" }}>34</span>
                    </div>
                    <Input
                        size="medium"
                        placeholder="Search"
                        prefix={<IoSearchOutline />}
                        style={{ width: "300px" }}
                        className="header-search-bar mb-2"
                    />
                    {conversations.map((convo, index) => (
                        <div
                            key={convo.id}
                            className={`convo-item ${selectedConvo?.id === convo.id ? 'active' : ''}`}
                            onClick={() => setSelectedConvo(convo)}
                        >
                            <div className="convo-img-wrapper">
                                <img src={convo.img} alt={convo.name} className="convo-img" />
                                <span className="convo-dot" style={{ backgroundColor: convo.dotColor }}></span>
                            </div>
                            <div className="convo-details">
                                <div className="d-flex justify-content-between">
                                    <div className="convo-name">{convo.name}</div>
                                    <div className="d-flex gap-2">
                                        {index < 3 && (
                                            <p style={{ backgroundColor: "#1b3a57", borderRadius: "20px", padding: "0px 5px", color: "#fff", fontSize: "10px" }}>New</p>
                                        )}
                                        {(index === 0 || index === 3) && (
                                            <p style={{ backgroundColor: "#1b3a57", borderRadius: "20px", padding: "0px 5px", color: "#fff", fontSize: "10px" }}>2</p>
                                        )}
                                    </div>
                                </div>
                                <div className="convo-message pt-0">{convo.message}</div>
                                <div className="convo-time">{convo.time}</div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
            {(selectedConvo && isMobile) || !isMobile ? (
                <div className="convo-messages">
                    {selectedConvo ? (
                        <div>
                            <div className="convo-header justify-content-between">
                                {isMobile && (
                                    <HiOutlineChevronLeft  
                                        className="exit-icon"
                                        onClick={() => setSelectedConvo(null)} 
                                        style={{ cursor: 'pointer', fontSize: '24px' }}
                                    />
                                )}
                                <div className="d-flex gap-2 convo-messages-header-left">
                                    <img src={selectedConvo.img} alt="Profile" className="convo-header-img" />
                                    <div className='convo-header-name-time'>
                                        <p className="convo-header-name mb-0 pb-2">{selectedConvo.name}</p>
                                        <p className="convo-header-status" style={{ fontSize: "12px" }}><GoDotFill style={{ color: "#1b3a57", height: "16px", width: "16px" }} />Online 12:55 am</p>
                                    </div>
                                </div>
                                <div className='convo-messages-header-right'>
                                    <div className="d-flex gap-2">
                                        <p style={{ backgroundColor: "#1b3a57", fontSize: "12px", borderRadius: "20px", padding: "5px", color: "#fff" }}>New Customer</p>
                                        <p style={{ color: "#1b3a57", fontSize: "14px", paddingTop: "5px" }}>View Profile</p>
                                    </div>
                                    <div className=" d-flex convo-header-actions justify-content-end">
                                        <IoBagOutline className="convo-header-icon" />
                                        <p style={{ fontSize: "15px", color: "#A6A8B1" }}>0 Orders</p>
                                    </div>
                                </div>
                            </div>

                            {messagesWithDate.map((msg, index) => (
                                msg.type === 'date' ? (
                                    <DateSeparator key={index} date={msg.date} />
                                ) : (
                                    <div key={index} className={`message ${msg.from === "You" ? 'from-you' : 'from-them'}`}>
                                        {msg.product && (
                                            <div className="product-info-chat gap-2">
                                                <img src={msg.product.img} alt={msg.product.name} className="chat-product" />
                                                <div className="d-flex stock-price">
                                                    <div className="product-details align-items-center d-flex flex-column justify-content-center">
                                                        <div className="product-name" style={{ fontWeight: "bold" }}>{msg.product.name}</div>
                                                        <div className="product-price">
                                                            {msg.product.price}
                                                        </div>
                                                    </div>
                                                    <div className="pt-4">
                                                        {msg.product.stock}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="message-text">
                                            {msg.text}
                                        </div>
                                        <div className="message-time">
                                            {msg.time} {msg.from === "You" && <FaCircleCheck />}
                                        </div>
                                    </div>
                                )
                            ))}
                            <Input
                                placeholder="Your Message"
                                className='customer-convo-text-area'
                                style={{
                                    position: "sticky",
                                    bottom: "0",
                                    overflowY: "auto",
                                }}
                                prefix={
                                    <FaSquarePlus
                                        style={{
                                            color: "#1b3a57",
                                            height: "20px",
                                            width: "20px",
                                        }}
                                    />
                                }
                                suffix={
                                    <Tooltip>
                                        <HiOutlineEmojiHappy
                                            style={{
                                                color: "#1b3a57",
                                                height: "20px",
                                                width: "20px",
                                                marginRight: "10px"
                                            }}
                                        />
                                        <Button icon={<LiaTelegramPlane style={{ height: "20px", width: "20px", color: "#fff" }} />} style={{ backgroundColor: "#1b3a57", color: "#fff" }}>
                                            Send
                                        </Button>
                                    </Tooltip>
                                }
                            />
                        </div>
                    ) : (
                        <div className="no-message">
                            <img src={messageImg} alt="No messages" style={{ width: "100px", height: "100px" }} className="mb-4" />
                            <h4>Messages</h4>
                            <p>Click on a contact to view messages</p>
                            <button style={{ borderRadius: "10px", backgroundColor: "#1b3a57", color: "#fff", padding: "10px 20px" }}><TbMessageDots style={{ width: "20px", height: "20px" }} /> New Message</button>
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default CustomerConvo;
