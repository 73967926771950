import React, { useState } from "react";
import profile from "../../Assets/images/singleuser.png";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu } from "antd";
import CustomerOrderList from "./CustomerOrdersList";
import CustomerCompletedOrderList from "./CustomerCompletdOrders";
import CustomerCancelledOrderList from "./CustomerCancelledOrder";
import CustomerPendingOrderList from "./CustomerPendingOrderList";
import InProgressStatusList from "./InProgressStatusList";
import ShippedStatusList from "./ShippedStatusList";
import { ArrowLeftOutlined } from "@ant-design/icons";

const CustomerDetails = () => {
  const location = useLocation();
  const { userData } = location.state || {};
  console.log("userData is:", userData)
  const handleBack = () => {
    navigate('/Users')
  }
  const items = [
    {
      label: "All Status",
      key: "allStatus",
    },
    {
      label: "Pending",
      key: "pending",
    },
    {
      label: "In Progress",
      key: "inProgress",
    },
    {
      label: "Shipped",
      key: "shipped",
    },
    {
      label: "Delivered",
      key: "delivered",
    },
    {
      label: "Cancelled",
      key: "cancelled",
    },

  ];
  const navigate = useNavigate();

  const [current, setCurrent] = useState("allStatus");

  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };
  const user = userData?.content?.length > 0 ? userData.content[0] : userData?.userDetails;
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  return (
    <>
      <div className="container">
        <div className="main-title-all justify-content-start d-flex">
          <span>Customer Details</span>
        </div>
        <div className="row customer-detail-information" >
          <div className="col-lg-4 ">
            <div className="personal-information-details" style={{ alignContent: "center" }}>
              <div className="d-flex gap-4 profile-name-email">
                <div className="profile-email-icon">
                  <img
                    src={userData?.imageData ? `data:image/jpeg;base64,${userData?.imageData}` : profile}
                    alt="Profile"
                  />
                </div>
                <div style={{ textAlign: "left" }}>
                  <p style={{ fontWeight: "bold", marginBottom: "0px", color:"#1b3a57" }}> {user?.username || user?.fullName || "N/A"}</p>
                  <p style={{ marginBottom: "0px", color:"gray" }}>{user?.email || "N/A"}</p>
                </div>
              </div>
            </div>

          </div>
          <div className="col-lg-4  personal-information-details ">
            <h5 style={{color:"#1b3a57"}}>Personal Information</h5>
            <div className="d-flex gap-3 personal-information-data">
              <div style={{ textAlign: "left" }}>
                <p style={{color:"gray"}}>Contact Number&nbsp;:</p>
                {/* <p>Gender</p> */}
                {/* <p>Date Of Birth</p> */}
                <p style={{color:"gray"}}>Member Since &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</p>
              </div>
              <div style={{ textAlign: "left" }}>
                <p style={{color:"gray"}}>{user?.mobile || "N/A"}</p>
                {/* <p>{user?.gender || "N/A"}</p> */}
                {/* <p>{user?.dob || "N/A"}</p> */}
                <p style={{color:"gray"}}>{user?.createdDate ? formatDate(user.createdDate) : "N/A"}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 personal-information-details">
            <h5 style={{color:"#1b3a57"}}>Shipping Address</h5>
            <p style={{ textAlign: "start", color:"gray" }}>{user?.shippingAddress?.streetAddress || "N/A"}</p>
            <p style={{ textAlign: "start", color:"gray" }}>
              {[
                user?.shippingAddress?.city || "N/A",
                user?.shippingAddress?.state,
                user?.shippingAddress?.country,
                user?.shippingAddress?.zipCode
              ].filter(Boolean).join(', ')}
            </p>
              <div className="d-flex gap-4 shipping-details">
              <div>
                <h4 style={{color:"#5d6679"}}>{user?.totalItemOrders || "0"}</h4>
                <p style={{color:"orange"}}>Total Order</p>
              </div>
              <div>
                <h4 style={{color:"#5d6679"}}>{user?.completedOrders || "0"}</h4>
                <p style={{color:"#28c76f"}}>Completed</p>
              </div>
              <div>
                <h4 style={{color:"#5d6679"}}>{user?.cancelledOrders || "0"}</h4>
                <p style={{color:"red"}}>Cancelled</p>
              </div>
            </div>
          </div>
        </div>
        <Menu
          style={{ marginBottom: "30px", marginTop: "10px" }}
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
          className="manage-order-menu"
        />
        {current === "allStatus" && <CustomerOrderList />}
        {current === "pending" && <CustomerPendingOrderList />}
        {current === "inProgress" && <InProgressStatusList />}
        {current === "shipped" && <ShippedStatusList />}
        {current === "delivered" && <CustomerCompletedOrderList />}
        {current === "cancelled" && <CustomerCancelledOrderList />}


        <button
          type="button"
          className="add-to-cart-button previous-button mb-3"
          onClick={() => handleBack()}
        >
          <ArrowLeftOutlined className="" />
          Previous
        </button>
      </div>

    </>
  );
};
export default CustomerDetails;
